<svelte:options tag="settings-modal" />

<script>
  import axios from "axios";
  import { onMount } from "svelte";
  import {
    getAuthKey,
    getPersonId,
    getPersonOrgOfficeId,
  } from "../../../utils/cookie/user";
  import { createEventDispatcher } from "svelte";
  import {
    STANDARD_DICTIONARIES_MODULE,
    UTILITY_MODULE,
  } from "../../../config/api-variables";
  import { alertMessageService } from "../../../common/AlertMessage/alertMessageService";

  const dispatch = createEventDispatcher();

  let accountSettingDetails = {};
  let languageList = [];
  let selectedObject = {};
  let selectedOption; // Set the initial selected option

  const headers = { Authorization: "Token " + getAuthKey() };

  onMount(() => {
    axios
      .post(
        STANDARD_DICTIONARIES_MODULE + "/organisation/language/list",
        { organisation_office_id: parseInt(getPersonOrgOfficeId()) },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          languageList = response.data.rows;
        }
        if (response.data.statusCode === "E100001")
          alertMessageService.error(response.data.message);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });

    axios
      .post(
        UTILITY_MODULE + "/account/settings/view",
        { person_id: parseInt(getPersonId()) },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          accountSettingDetails = response.data.rows;
          selectedOption = response.data.rows?.translate_language?.name;
        }
        if (response.data.statusCode === "E100001")
          alertMessageService.error(response.data.message);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  });

  const updateLanguage = () => {
    const payload = {
      id: parseInt(getPersonId()),
      person_id: parseInt(getPersonId()),
      organisation_office_id: parseInt(getPersonOrgOfficeId()),
      // translate_language: selectedObject,
      translate_language: {
        id: selectedObject?.id,
        code: selectedObject?.language_code,
        name: selectedObject?.language_name,
        iso_code: null,
        language_name_local: null,
      },
      language_id: null,
      content_language_ids: null,
      date_format: null,
      time_format: null,
      currency_id: null,
    };

    axios
      .post(
        UTILITY_MODULE + "/account/settings/update",
        { ...payload },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          accountSettingDetails = response.data.rows;
          alertMessageService.success("Language Updated Successfully!");
        }
        if (response.data.statusCode === "E100001")
          alertMessageService.error(response.data.message);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
    // console.log(payload, 'payload')
  };

  function handleSelect(event) {
    selectedOption = event.target.value;

    selectedObject = languageList.find(
      (option) => option.language_name === selectedOption,
    );
  }
</script>

<div class="modal-content">
  <h3 style="0 4px 8px 4px;">Select Language</h3>
  <div style="margin-top: 8px; display: flex; flex-direction: column;">
    {#each languageList as option (option.id)}
      <label
        class="label"
        style="display: flex; justify-content: space-between; padding: 4px;"
      >
        <span>{option.language_name_local}</span>
        <input
          class="radio"
          type="radio"
          name="options"
          value={option.language_name}
          group="options"
          style="margin-left: auto;"
          checked={selectedOption === option.language_name}
          on:change={() => {
            selectedOption = option.language_name;
            selectedObject = option;
          }}
        />
      </label>
    {/each}

    <button
      class="primary-background-color custom-text-color"
      style="width: 100%; padding: 10px; border: none; border-radius: 8px; margin-top: 8px;"
      on:click={updateLanguage}
      type="button"
    >
      <p>Submit</p>
    </button>
  </div>
</div>
