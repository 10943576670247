<svelte:options tag="chat-list-item" />

<script>
  export let item;
  export let access_by_type;
  export let isCallback;

  import { marked } from "marked";
  import { onMount } from "svelte";
  import TimeAgo from "../../../common/TimeAgo.svelte";
  import { DOMAIN } from "../../../config/api-variables";
  import { HELPDESK, HELPDESK_COLORS } from "../../../config/global-enums";
  import { DeviceType } from "../../../stores/authStores";
  import { createEventDispatcher } from "svelte";
  import ChangeCallbackStatus from "../../ChatWidget/components/Actions/ChangeCallbackStatus.svelte";
  // import ChangeCallbackStatus from '../../Actions/ChangeCallbackStatus.svelte';
  import { generateColorFromNumber } from "../../../utils/tools/data-transformers";

  const dispatch = createEventDispatcher();

  let last_comment;

  let status_name;
  let ticket_id;
  let avatarLetter;
  let itemId;
  let backgroundColor;

  let bodyContent = "";

  let unreadCount = 0;
  let unreadCountVisible = false;

  let markedAs = null;

  let isMine = false;

  let showTicketIdAndStatus = false;

  let callbackStatus = null;

  let visibleCallbackModal = false;

  $: if (!!item?.ticket_id) {
    showTicketIdAndStatus = true;
  } else {
    showTicketIdAndStatus = false;
  }

  onMount(() => {
    itemId = item?.id;
    backgroundColor = generateColorFromNumber(itemId);
    last_comment = item?.last_comment.content;
    markedAs = item?.marked_as;
    if (item?.unread_count !== 0) {
      unreadCountVisible = true;
      unreadCount = item?.unread_count;
    } else {
      unreadCountVisible = false;
    }
    if (!!item.marked_as && item.marked_as !== "read") {
      unreadCountVisible = true;
    } else {
      unreadCountVisible = false;
    }
    // stripHTMLTags();

    // extractBodyContent();
    avatarLetter = item?.title.charAt(0).toUpperCase();

    if (item.current_status_name !== null) {
      status_name = item.current_status_name;
    }
    if (item.ticket_id !== null) {
      ticket_id = item.ticket_id;
      showTicketIdAndStatus = true;
    }
    if (item?.callback_status && !callbackStatus) {
      callbackStatus = item?.callback_status;
    }
  });

  function extractBodyContent(havinesh) {
    // const parser = new DOMParser();
    // const doc = parser.parseFromString(havinesh, "text/html");
    // // const doc = parser.parseFromString(last_comment, 'text/html');
    // const body = doc.querySelector("body");

    // if (body) {
    //   bodyContent = body.innerHTML;
    // }
    // const tempElement = document.createElement("div");
    // tempElement.innerHTML = bodyContent;
    // const textContent = tempElement.textContent || tempElement.innerText;
    // bodyContent = textContent;
    // return textContent;

    let html;

    if (!!havinesh) {
      html = marked(havinesh);
    }
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  }

  const getStatusColor = (statusName) => {
    const statusKey = Object.keys(HELPDESK).find(
      (el) => HELPDESK[el] === statusName,
    );
    return HELPDESK_COLORS[statusKey];
  };

  const getTypeOfTicket = (typeOfTicket) => {
    switch (typeOfTicket) {
      case "C":
        return "Complaint";
      case "Q":
        return "Query";
      case "R":
        return "Request";
      case "G":
        return "Query";
      // return 'Generic';
      case "T":
        return "Ticket";
      default:
        return " - ";
    }
  };
  const getTypeOfTicketColor = (typeOfTicket) => {
    switch (typeOfTicket) {
      case "C":
        return "#f6cdce";
      case "Q":
        return "#9ff2fb";
      case "R":
        return "#fcf1d5";
      case "G":
        return "#9ff2fb";
      // return '#CFE2FF';
      case "T":
        return "#f6cdce";
      default:
        return null;
    }
  };

  const updateCallbackStatus = (event) => {
    const data = event.detail;
    const payload = {
      ticket_main_id: item?.id,
      status: data.selectedStatus,
    };
    dispatch("ChangeCallbackStatus", payload);
    callbackStatus = data.selectedStatus;
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->

<div
  style="display: flex;"
  on:click={() => {
    unreadCount = 0;
    unreadCountVisible = false;
  }}
  on:keydown={() => console.log("")}
>
  {#if access_by_type === "customer"}
    <img
      src={DOMAIN + item?.agent_avatar}
      alt="a"
      style="height: 36px; border-radius: 50%; margin-right: 8px;"
    />
  {:else if item?.person_avatar && item?.person_avatar !== "/media/default/profile/person/default.png"}
    <img
      src={DOMAIN + item?.person_avatar}
      alt="a"
      style="height: 36px; border-radius: 50%; margin-right: 8px;"
    />
  {:else}
    <div
      class="avatar"
      style="background-color: {backgroundColor}; width: 36px;height: 36px; display: flex;align-items: center;justify-content: center;border-radius: 50%;color: white;font-size: 18px; aspect-ratio: 1; line-height: 0;"
    >
      {avatarLetter}
    </div>
  {/if}

  <div
    style="padding-left: 8px; {$DeviceType === 'desktop' &&
      'max-width: 222px'};"
  >
    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <p
        style="font-size: 14px; margin: 0px; text-transform: capitalize; font-weight: 600"
      >
        {#if access_by_type === "customer"}
          {item?.agent_name.toLowerCase()}
        {:else}
          {item?.title.toLowerCase()}
        {/if}
      </p>
      <div style="display: flex; align-items: center;">
        <div class="time-ago-style">
          <!-- <TimeAgo date={item?.last_comment.created_at} live/> -->
          <TimeAgo timestamp={item?.last_comment.created_at} />
        </div>
        <div class="dropdown" style="margin-left: auto">
          <button
            class="more-button-style"
            style="display: flex; padding-right: 0;"
          >
            <svg
              style="height: 14px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              ><defs
                ><style>
                  .cls-1 {
                    opacity: 0;
                  }
                  .cls-2 {
                  }
                </style></defs
              ><title>more</title><g id="Layer_2" data-name="Layer 2"
                ><g id="more-vertical"
                  ><g id="more-vertical-2" data-name="more-vertical"
                    ><rect
                      class="cls-1"
                      width="24"
                      height="24"
                      transform="translate(0 24) rotate(-90)"
                    /><circle class="cls-2" cx="12" cy="12" r="2" /><circle
                      class="cls-2"
                      cx="12"
                      cy="5"
                      r="2"
                    /><circle class="cls-2" cx="12" cy="19" r="2" /></g
                  ></g
                ></g
              ></svg
            >
          </button>
          <div
            class="dropdown-content"
            style="left:-95px; padding: 4px; border-radius: 8px; z-index: 99999999 !important; min-width: 106px"
          >
            {#key markedAs}
              {#if markedAs !== "read" || unreadCount !== 0}
                <button
                  class="dropdown-button"
                  on:click|stopPropagation={() => {
                    dispatch("MarkAsReadClicked", item);
                    markedAs = "read";
                    unreadCount = 0;
                    unreadCountVisible = false;
                  }}
                >
                  Mark as read
                </button>
              {:else}
                <button
                  class="dropdown-button"
                  on:click|stopPropagation={() => {
                    dispatch("MarkAsUnreadClicked", item);
                    markedAs = "unread";
                    unreadCountVisible = true;
                  }}
                >
                  Mark as unread
                </button>
              {/if}
              {#if access_by_type === "employee" && item?.callback_status}
                <button
                  class="dropdown-button"
                  on:click|stopPropagation={() => {
                    // markedAs = 'unread';
                    // unreadCountVisible = true;
                    visibleCallbackModal = true;
                  }}
                >
                  Change callback status
                </button>
              {/if}
            {/key}
          </div>
        </div>
      </div>
    </div>

    <div
      style="align-items: center; display: flex; font-size: 12px; margin-top: 4px"
    >
      {#if item?.callback_status && isCallback}
        <span style="border-radius: 4px; background: #f3f2f2; padding: 4px;"
          >{callbackStatus}</span
        >
      {:else}
        <span
          style="border-radius: 4px; background: {getTypeOfTicketColor(
            item?.type_of_ticket,
          )}; padding: 4px;">{getTypeOfTicket(item?.type_of_ticket)}</span
        >
        {#key showTicketIdAndStatus}
          {#if showTicketIdAndStatus}
            <span
              style="margin: 4px; border-right: 1.5px solid #979797; height: 15px;"
            ></span>
            <span tyle="padding: 4px;">{item?.ticket_id}</span>
            <span
              style="margin: 4px; border-right: 1.5px solid #979797; height: 15px;"
            ></span>
            <span
              style="border-radius: 4px; background: {getStatusColor(
                status_name,
              )}; padding: 4px;">{status_name}</span
            >
          {:else}
            <!-- <span style="margin: 4px; border-right: 1.5px solid #979797; height: 15px;"></span>
                <span style="border-radius: 4px; padding: 4px; text-transform: capitalize;">{item?.conversation_status}</span> -->
          {/if}
        {/key}
      {/if}
    </div>
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <p
        style="margin: 4px 0px 0px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 12px; display: flex; width: {unreadCountVisible
          ? 'calc(100% - 20px)'
          : '100%'};"
      >
        <span
          style="white-space: nowrap; width: calc(100vw - 90px); overflow: hidden; text-overflow: ellipsis;"
        >
          {extractBodyContent(item?.last_comment?.content)}
        </span>
      </p>
      {#if unreadCountVisible}
        <button
          class="primary-background-color custom-text-color"
          style="border-radius: 4px; border:none; font-size: 10px; min-height: 13.5px"
        >
          {#if unreadCount > 0 && markedAs !== "read"}
            {unreadCount}
          {:else}
            {" "}
          {/if}
        </button>
      {/if}
    </div>
  </div>
</div>

<ChangeCallbackStatus
  bind:showModal={visibleCallbackModal}
  on:selectedStatusData={updateCallbackStatus}
/>
