<svelte:options tag="cleandesk-message-loading" />

<script>
  import { DOMAIN } from "../../../config/api-variables";

  export let aiDetails = null;
</script>

<div class="message-item-container">
  <div style="width: 32px;">
    {#if !!aiDetails?.title}
      <div
        class="chat-header-avatar"
        style="display: flex; align-items: center;"
      >
        <img
          src={DOMAIN + aiDetails?.person_avatar}
          alt="a"
          style="height: 24px; border-radius: 50%; margin-right: 8px;"
        />
      </div>
    {/if}
  </div>
  <div style="width: 100%;">
    {#if !!aiDetails?.title}
      <div
        class="chat-header-avatar"
        style="display: flex; align-items: center;"
      >
        <div
          class="header-profile-name"
          style="font-size: 16px; font-weight: 600;"
        >
          {aiDetails?.title.toLowerCase()}
        </div>
      </div>
    {/if}
    <div
      style="padding: 16px; margin: 0 0 0 -15px; width: 50px; border-radius: 8px"
    >
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
</div>
