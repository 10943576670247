export const getContrastYIQ = (hexColor) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5), 16);
  const yiq = (r * 269 + g * 567 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
};

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const generateColorFromNumber = (number) => {
  const r = (number & 0xff0000) >> 16;
  const g = (number & 0x00ff00) >> 8;
  const b = number & 0x0000ff;

  return `rgb(${r}, ${g}, ${b})`;
};

export const removeHttpHttpsWww = (url) => {
  url = url.replace(/^https?:\/\//, "");

  url = url.replace(/^www\./, "");

  return url;
};

export const capitalizeFirst = (value) =>
  value.trim().length > 1
    ? value.charAt(0).toUpperCase() + value.slice(1)
    : value;

export const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const capitalizeWords = (sentence) => {
  return sentence.replace(/\b\w+\b/g, (match) => {
    return capitalizeFirstLetter(match);
  });
};

export const getRandomColor = () => {
  const randomValue = () => Math.floor(Math.random() * 256);
  const r = randomValue();
  const g = randomValue();
  const b = randomValue();
  return `rgb(${r},${g},${b})`;
};

export const getRandomSuggestionBgColor = () => {
  const colors = [
    { backgroundColor: "#000000", textColor: "#FFFFFF" },
    { backgroundColor: "#FFFFFF", textColor: "#000000" },
  ];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};
