<svelte:options tag="cleandesk-campaign-card" />

<script>
  import { DOMAIN } from "../../../config/api-variables";

  export let campaign;
</script>

<div class="cleandesk-product-main-div">
  {#if campaign?.media_url}
    {#if campaign?.media_type === "image"}
      <img
        class="cleandesk-product-img"
        src={DOMAIN + campaign?.media_url}
        width="100%"
        style="height: auto;"
        alt="campaign"
      />
    {/if}
    {#if campaign?.media_type === "video"}
      <video
        class="cleandesk-product-img"
        src={DOMAIN + campaign?.media_url}
        width="100%"
        style="height: auto;"
        controls
      >
        <track kind="captions" />
        Your browser does not support the video tag.</video
      >
    {/if}
  {/if}
  <div class="cleandesk-product-content-div">
    <div>
      {#if campaign?.campaign_name}
        <p class="cleandesk-product-title">{campaign?.campaign_name}</p>
      {/if}
      <div>
        {#each Array(5) as _, index}
          {#if index < Math.floor(campaign?.rating)}
            <svg class="cleandesk-star">
              <use xlink:href="#star"></use>
            </svg>
          {:else if index === Math.floor(campaign?.rating) && campaign?.rating % 1 !== 0}
            <svg class="cleandesk-star" viewBox="0 0 24 24">
              <clipPath id="half-star-clip">
                <rect
                  x="0"
                  y="0"
                  width={24 * (campaign?.rating % 1)}
                  height="24"
                />
              </clipPath>
              <use xlink:href="#star" clip-path="url(#half-star-clip)" />
            </svg>
          {/if}
        {/each}
      </div>
      {#if campaign?.caption}
        <p class="cleandesk-product-description">
          {@html campaign?.caption}
        </p>
      {/if}
    </div>

    {#if campaign?.discounted_price || campaign?.product_price || campaign?.call_to_action}
      <div class="cleandesk-price-div">
        <div>
          {#if campaign?.discounted_price}
            <span
              class="cleandesk-price-span"
              style=" text-decoration: line-through solid red 2px; font-weight: 500;"
            >
              {campaign?.discounted_price}</span
            >
          {/if}
          {#if campaign?.product_price}
            <span class="cleandesk-price-span" style="color: black"
              >{campaign?.product_price}</span
            >
          {/if}
        </div>

        {#if campaign?.call_to_action}
          <button
            on:click={() => window.open(campaign?.cta_url, "_blank")}
            class="cleandesk-buy-button primary-background-color custom-text-color"
            >{campaign?.call_to_action}</button
          >
        {/if}
      </div>
    {/if}
  </div>
</div>

<svg style="display:none;">
  <symbol id="star" viewBox="0 0 24 24">
    <path
      d="M12 2l3.09 6.32 6.91.91-5 4.86 1.18 6.89-6.18-3.25-6.18 3.25 1.18-6.89-5-4.86 6.91-.91z"
    />
  </symbol>
  <symbol id="star-empty" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M12 17.27l-4.09 2.45 1-5.83-3.34-3.26 5.83-.85L12 5.1l2.59 5.48 5.83.85-3.34 3.26 1 5.83z"
    />
  </symbol>
</svg>
