<svelte:options tag="cleandesk-menu-button" />

<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { DOMAIN } from "../../config/api-variables";
  import MenuItemWithIcon from "./MenuItemWithIcon.svelte";
  import SubMenuButton from "./SubMenuButton.svelte";

  export let menuItem;
  export let expanded = false;
  export let isSelected;
  export let selectedMenuItem;
  export let expanMenuItem = null;
  export let isExtraMenu = false;

  export let disableMenu = false;

  let areThereChildren = false;
  let areChildrenExpanded = false;
  let menuChildren = [];

  $: if (menuItem?.children?.length > 0) {
    areThereChildren = true;
  }

  $: if (!expanded) {
    areChildrenExpanded = false;
  }

  $: if (!!expanMenuItem && expanMenuItem?.id === menuItem?.id) {
    areChildrenExpanded = true;
  }

  const dispatch = createEventDispatcher();

  function handleClick(item) {
    if (areThereChildren) {
      if (!expanded) {
        dispatch("expandMenu", { item });
        areChildrenExpanded = !areChildrenExpanded;
      } else {
        areChildrenExpanded = !areChildrenExpanded;
      }
    } else {
      dispatch("menuItemClick", { item });
    }
  }

  function handleSubMenuClick(item) {
    dispatch("menuItemClick", { item: item?.detail?.item });
  }

  $: if (menuItem?.children) {
    menuChildren = menuItem?.children?.sort(
      (a, b) => parseFloat(a.weight) - parseFloat(b.weight),
    );
  }

  function checkFileExtension(fileName) {
    const allowedExtensions = ["png", "jpeg", "jpg"];

    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (allowedExtensions.includes(fileExtension)) {
      return true;
    } else {
      return false;
    }
  }

  let menuIcon;
  let isNotSvgIcon = false;

  let isIconAvailable = false;
  let firstAlphabet = "";
  let menuTitle = "";

  onMount(() => {
    menuTitle = isExtraMenu ? menuItem?.name : menuItem?.title;

    if (menuItem?.icon || menuItem?.icon_url) {
      menuIcon = menuItem?.icon || menuItem?.icon_url;
      isNotSvgIcon = checkFileExtension(menuIcon);
      isIconAvailable = true;
    } else {
      isIconAvailable = false;
      firstAlphabet = menuTitle?.charAt(0);
    }
  });
</script>

<button
  class="cleandesk-left-menu-item {isSelected
    ? 'secondary-background-color'
    : ''}"
  on:click={() => handleClick(menuItem)}
  style="{!expanded ? 'margin: 0 0.2em;' : 'margin: 0 1em;'} {disableMenu
    ? 'pointer-events: none; opacity: 0.6;'
    : ''}"
  disabled={disableMenu}
>
  <div class="tooltip" style={!expanded && "justify-content: center"}>
    {#if expanded}
      <div
        style="display: flex; align-items: center; justify-content: space-between; width: 100%;"
      >
        <div style="display: flex; align-items: center;">
          {#if isIconAvailable}
            {#if isNotSvgIcon}
              <img src={DOMAIN + menuIcon} alt="logo" width="24" />
            {:else}
              <div class="cleandesk-menu-icon-div" style="width: 24px;">
                {@html menuIcon}
              </div>
            {/if}
          {:else}
            <div
              class="cleandesk-menu-icon-div"
              style="width: 24px; display: flex; justify-content: center; align-items: center; font-weight: 600; font-size: 20px;"
            >
              {firstAlphabet}
            </div>
          {/if}
          <span class="cleandesk-left-name">{menuTitle}</span>
        </div>
        {#if areThereChildren}
          <div>
            {#if !areChildrenExpanded}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                ><path
                  d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"
                /></svg
              >
            {:else}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
                ><path
                  d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z"
                /></svg
              >
            {/if}
          </div>
        {/if}
      </div>
    {:else}
      {#if isIconAvailable}
        {#if isNotSvgIcon}
          <img src={DOMAIN + menuIcon} alt="logo" width="24" />
        {:else}
          <div class="cleandesk-menu-icon-div" style="width: 24px;">
            {@html menuIcon}
          </div>
        {/if}
      {:else}
        <div
          class="cleandesk-menu-icon-div"
          style="width: 24px;  display: flex; justify-content: center; align-items: center; font-weight: 600; font-size: 20px;"
        >
          {firstAlphabet}
        </div>
      {/if}
      <span class="tooltiptext">{menuTitle}</span>
    {/if}
  </div>
</button>

{#if areThereChildren && areChildrenExpanded}
  {#each menuChildren as child}
    <SubMenuButton
      menuItem={child}
      {expanded}
      {isSelected}
      {selectedMenuItem}
      {expanMenuItem}
      on:menuItemClick={handleSubMenuClick}
      on:expandMenu={handleClick}
    />
    <!-- <button
      class="cleandesk-left-menu-item {selectedMenuItem?.id === child?.id
        ? 'secondary-background-color'
        : ''}"
      on:click={() => handleSubMenuClick(child)}
      style={!expanded ? "margin: 0 0.2em;" : "margin: 0 1.8em;"}
    >
      <div class="tooltip" style={!expanded && "justify-content: center"}>
        {#if expanded}
          <MenuItemWithIcon icon={child?.icon} />
          <span class="cleandesk-left-name">{child?.title}</span>
        {:else}
          <MenuItemWithIcon icon={child?.icon} />
          <span class="tooltiptext">{child?.title}</span>
        {/if}
      </div>
    </button>

    {#if child.children && child.children.length > 0 && areChildrenExpanded}
      <div style="padding-left: 1em;">
        {#each child.children as grandChild}
          {grandChild?.title}
        {/each}
      </div>
    {/if} -->
  {/each}
{/if}
