<svelte:options tag="cleandesk-mobile-bottom-sheet" />

<script>
  import MenuButton from "../../components/Common/MenuButton.svelte";
  import MobileBottomMenu from "../../components/Common/MobileBottomMenu.svelte";
  import { fly } from "svelte/transition";
  import { createEventDispatcher, onMount, afterUpdate } from "svelte";
  import { LEGISLATOR_MANAGEMENT_MODULE } from "../../config/api-variables";
  import {
    getAuthKey,
    getPersonId,
    getPersonOrgOfficeId,
  } from "../../utils/cookie/user";
  import axios from "axios";
  import { DeviceType, disableServiceMenu } from "../../stores/authStores";
  import {
    aiMessageLoading,
    extraMenuClicked,
    messageLoading,
    ticketMainId,
  } from "../../stores/chatStores";
  import {
    menuItemsList,
    menuItemsLoading,
    selectedMenuItem,
  } from "../../stores/menuStores";
  import { chatSocket } from "../../utils/socket/socketConfig";
  import { PlaySound } from "../../utils/PlaySound";

  export let expanded = false;
  export let isWidget = false;

  const dispatch = createEventDispatcher();

  let moreExpanded = false;

  let maxVisibleItems = 4;

  $: if (isWidget && $DeviceType !== "mobile") {
    maxVisibleItems = 3;
  }

  let selectedMenu;

  let visibleItems = [];
  let hiddenItems = [];

  let expanMenuItem;

  let menuItems = [];

  let displayMenuItems = [];

  let filteredMenuItems = [];

  // Container reference to measure overflow
  let container;

  let extraMenuItems = [];

  const homeClicked = (e) => {
    if (!!e.detail.item.children?.length > 0) {
      displayMenuItems = e.detail.item.children;
      moreExpanded = true;
      expanMenuItem = e.detail.item;
      selectedMenu = e.detail.item;
    } else {
      displayMenuItems = filteredMenuItems?.slice(maxVisibleItems);
      moreExpanded = false;
      dispatch("menuItemClick", e.detail);
    }
  };

  const expandLeftMenu = (e) => {
    displayMenuItems = filteredMenuItems?.slice(maxVisibleItems);
    // dispatch("expandMenu");
    moreExpanded = true;
  };

  // Function to detect overflow and adjust maxVisibleItems dynamically
  const detectOverflow = () => {
    if (container) {
      const containerWidth = container?.clientWidth;
      let totalWidth = 0;
      // maxVisibleItems = 0;

      container.querySelectorAll(".menu-button").forEach((button, index) => {
        totalWidth += button.offsetWidth;

        if (totalWidth + 46 <= containerWidth) {
          maxVisibleItems = index + 1;
        }
      });
    }
  };

  // Re-run overflow detection when necessary
  afterUpdate(detectOverflow);
  onMount(() => {
    fetchMenuList();
    fetchExtraMenuList();
    window.addEventListener("resize", detectOverflow); // Recalculate on resize
  });

  function buildHierarchy(items) {
    // Sort items by weight
    items.sort((a, b) => parseFloat(a.weight) - parseFloat(b.weight));

    const map = new Map();
    const roots = [];

    items.forEach((item) => {
      item.children = [];
      map.set(item.id, item);
    });

    items.forEach((item) => {
      if (item.parent_menu_id !== null) {
        const parent = map.get(item.parent_menu_id);
        if (parent) {
          parent.children.push(item);
        } else {
          roots.push(item);
        }
      } else {
        roots.push(item);
      }
    });

    // Build hierarchy based on weight
    items.forEach((item) => {
      const parentWeight = (
        Math.floor(parseFloat(item.weight) * 10) / 10
      ).toFixed(2);
      const parent = items.find((i) => i.weight === parentWeight);
      if (
        parent &&
        parent.id !== item.id &&
        !parent.children.some((child) => child.id === item.id)
      ) {
        parent.children.push(item);
        const index = roots.indexOf(item);
        if (index > -1) {
          roots.splice(index, 1);
        }
      }
    });

    return roots;
  }

  $: if (menuItems) {
    filteredMenuItems = buildHierarchy(menuItems);

    detectOverflow();
  }

  $: {
    if (filteredMenuItems.length <= maxVisibleItems) {
      visibleItems = filteredMenuItems;
      hiddenItems = [];
    } else {
      visibleItems = filteredMenuItems.slice(0, maxVisibleItems);
      hiddenItems = filteredMenuItems.slice(maxVisibleItems);
    }
  }

  $: if (visibleItems) {
    visibleItems = visibleItems.sort(
      (a, b) => parseFloat(a.weight) - parseFloat(b.weight),
    );
  }

  const fetchMenuList = () => {
    const headers = { Authorization: "Token " + getAuthKey() };

    axios
      .post(
        LEGISLATOR_MANAGEMENT_MODULE + "/menu/visible/list",
        { organisation_office_id: getPersonOrgOfficeId() },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          menuItems = response?.data?.rows;
          menuItemsLoading.set(false);
          menuItemsList.set(menuItems);
          if (menuItems.length > 0) {
            selectedMenuItem.set(menuItems[0]);
          } else {
            selectedMenuItem.set({ id: null });
          }
        }
        if (response.data.statusCode === "E100001")
          alert(response.data.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchExtraMenuList = () => {
    const headers = { Authorization: "Token " + getAuthKey() };

    axios
      .post(
        LEGISLATOR_MANAGEMENT_MODULE + "/service/menu/visible/list",
        { organisation_office_id: getPersonOrgOfficeId() },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          extraMenuItems = response?.data?.rows;
        }
        if (response.data.statusCode === "E100001")
          alert(response.data.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const moreMenu = {
    id: "more",
    title: "More",
    name: "More",
    icon: `<svg class="cleandesk-left-menu-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400Z"/></svg>`,
  };

  const extraMenuItemClicked = (e) => {
    extraMenuClicked.set(e.detail.item);
    serviceClicked(e.detail.item);
  };

  function serviceClicked(item) {
    $chatSocket.emit("chat_ai_ticket_message_v2", {
      app_type: "CUSTOMER",
      organisation_office_id: getPersonOrgOfficeId(),
      constituency_id: 1,
      ticket_main_id: $ticketMainId,
      person_id: getPersonId(),
      content: item?.prompt,
      is_media_available: null,
      is_location_available: null,
      latitude: null,
      longitude: null,
      locality: null,
      address: null,
      category_id: null,
      ticket_id: null,
      menu_id: $selectedMenuItem?.id,
      required_input_data: {
        tool_name: item?.tool_name,
        prompt: item?.prompt,
      },
    });
    PlaySound("https://testcdn.cleandesk.co.in/sounds/send-message-sound.mp3");
    messageLoading.set(true);
    aiMessageLoading.set(true);
    moreExpanded = false;
  }
</script>

<div
  bind:this={container}
  style="display: flex; justify-content: space-between; padding: 4px 16px; border-top: 0.5px solid #707070; background-color: #fff;"
>
  {#each visibleItems as menuItem}
    <MobileBottomMenu
      {menuItem}
      {expanded}
      isSelected={$selectedMenuItem?.id === menuItem?.id}
      on:menuItemClick={homeClicked}
      on:expandMenu={expandLeftMenu}
    />
  {/each}

  {#if hiddenItems.length > 0 || extraMenuItems.length > 0}
    <MobileBottomMenu
      menuItem={moreMenu}
      {expanded}
      on:menuItemClick={() => expandLeftMenu(true)}
      on:expandMenu={() => expandLeftMenu(true)}
    />
  {/if}
</div>

{#if moreExpanded}
  <div
    class="cleandesk-bottom-sheet"
    transition:fly={{ y: 500, duration: 400 }}
  >
    <div
      style="display: flex; flex-direction: column; height: calc(100% - 1em); padding: 1em 0; overflow: scroll;"
    >
      {#each displayMenuItems as menuItem}
        <MenuButton
          {menuItem}
          expanded={true}
          isSelected={$selectedMenuItem?.id === menuItem?.id}
          selectedMenuItem={$selectedMenuItem}
          {expanMenuItem}
          on:menuItemClick={homeClicked}
          on:expandMenu={expandLeftMenu}
        />
      {/each}

      {#if extraMenuItems.length > 0}
        <div style="margin-top: 8px;">
          <span
            style="font-size: 12px; font-weight: 600; font-style: italic; margin: 0 1em; "
          >
            Service agents
          </span>
        </div>

        {#each extraMenuItems as extraMenuItem}
          <MenuButton
            isExtraMenu={true}
            menuItem={extraMenuItem}
            expanded={true}
            disableMenu={$disableServiceMenu ||
              $messageLoading ||
              $aiMessageLoading}
            on:menuItemClick={extraMenuItemClicked}
            on:expandMenu={expandLeftMenu}
          />
        {/each}
      {/if}
    </div>
    <button
      on:click={() => (moreExpanded = false)}
      class="cleandesk-bottom-sheet-close">X</button
    >
  </div>
{/if}
