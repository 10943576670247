<svelte:options tag="cleandesk-alert-message-container" />

<script>
  import AlertMessage from "./AlertMessage.svelte";
  import { messages } from "./alertMessageService";
</script>

<div class="message-container">
  {#each $messages as { id, message, type, duration }, index (id)}
    <AlertMessage {message} {type} {duration} {index} />
  {/each}
</div>
