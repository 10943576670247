<svelte:options tag="cleandesk-offers-card" />

<script>
  export let coupon;

  let copied = false;

  function copyCode(code) {
    const el = document.createElement('textarea');
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    copied = true;
    setTimeout(() => {
      copied = false;
    }, 1500);
  }

</script>

<div class="card-shadow right-side-window-card">
  <div>
    <div style="padding: 12px 16px;">
      <h3 style="margin: 0; font-size: 20px; font-weight: 600; color: #242424">{coupon?.title}</h3>
      <p style="margin: 0; font-size: 14px; color: #242424">{coupon?.description}</p>
      <!-- <p style="margin: 0; font-size: 14px; color: #242424; margin-top: 8px ">Code: {coupon?.code}</p> -->
      <p style="margin: 0; font-size: 14px; color: #242424; margin-top: 8px;">Code:
        <span style="cursor: copy;" class="copy-code" on:click="{() => copyCode(coupon?.code)}" on:keydown="{() => {}}">{coupon?.code}</span>
      </p>
      <!-- <div id="copySuccessMessage" style="position: absolute; top: 100%; left: 50%; transform: translateX(-50%); background-color: #f0f0f0; padding: 4px 8px; border-radius: 4px; display: none;">
        Copied!
      </div> -->
      <div style="position: absolute; top: 100%; left: 50%; transform: translateX(-50%); background-color: #f0f0f0; padding: 4px 8px; border-radius: 4px;">
          Copied!
        </div>
      {#if copied}
        <div style="position: absolute; top: 100%; left: 50%; transform: translateX(-50%); background-color: #f0f0f0; padding: 4px 8px; border-radius: 4px;">
          Copied!
        </div>
      {/if}
    </div>
  </div>

  <div style="background: #f5f5f5; border-radius: 0 0 8px 8px">
    {#if coupon?.details?.length > 0}
      <button on:click={() => coupon.expanded = !coupon.expanded} style="border: none; padding: 4px 12px; color: #0c5497; cursor: pointer; display: flex; align-items: center;">
        {#if coupon.expanded}
          Hide Details
          <svg style="height: 16px; fill: #0c5497; margin-left: 4px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z"/></svg>
        {:else}
          Show Details
          <svg style="height: 16px; fill: #0c5497; margin-left: 4px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
        {/if}
      </button>
      {#if coupon.expanded}
        <!-- <div transition:slide={{ delay: 0, duration: 300, easing: quintOut, axis: 'y' }} style="transition: height 0.3s;"> -->
          <div style="padding: 0px 20px 4px">
            {#each coupon.details as detail}
            <p style="margin: 0; font-size: 14px; color: #242424">{detail}</p>
            {/each}
          </div>
        <!-- </div> -->
      {/if}
    {/if}
  </div>
</div>