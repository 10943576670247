<svelte:options tag="chat-listing" />

<script>
  export let access_by_type;
  // export let selectedMessage;

  import StartNewConversationButton from "../../common/components/StartNewConversationButton.svelte";
  import SearchMain from "../../common/components/SearchMain.svelte";
  import axios from "axios";
  import { onMount } from "svelte";
  import InfiniteScroll from "../../common/InfiniteScroll.svelte";
  import { createEventDispatcher } from "svelte";
  import {
    getAuthKey,
    getPersonId,
    getPersonOrgOfficeId,
  } from "../../utils/cookie/user";
  import TimeAgo from "../../common/TimeAgo.svelte";
  import ChatListItem from "./components/ChatListItem.svelte";
  import {
    DeviceType,
    OrganisationDetails,
    widgetType,
  } from "../../stores/authStores";
  import io from "socket.io-client";
  import {
    BASIC_AI_URL,
    CHAT_DOMAIN,
    DOMAIN,
    HELPDESK_MODULE_V2,
    HELPDESK_MODULE,
  } from "../../config/api-variables";
  import { fly } from "svelte/transition";
  import { circOut } from "svelte/easing";
  import SettingsModal from "../ChatWidget/components/SettingsModal.svelte";
  import { AppDefaults } from "../../config/global-enums";
  import { PlaySound } from "../../utils/PlaySound";
  // import ChangeCallbackStatus from "../Actions/ChangeCallbackStatus.svelte";
  import { chatSocket, isSocketReset } from "../../utils/socket/socketConfig";
  import { selectedConversation } from "../../stores/chatStores";

  const dispatch = createEventDispatcher();

  let drawerVisible = false;
  let hideDrawerBody = false;
  let showLanguageSettings = false;

  let chatListLoading = false;

  let searchQuery = null;
  let selectedItem;

  let page = 1;

  let chatList = [];

  let newBatch = [];

  let timeoutId;

  let isOnlyTickets = false;
  let isCallback = false;

  let translatedText = "";

  const menuItems = [
    // {
    //   id: 1,
    //   label: "History",
    //   icon: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#000000}</style><path d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z"/></svg>`,
    //   action: () => {
    //     console.log("History");
    //   },
    // },
    {
      id: 2,
      label: "Language Settings",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#000000}</style><path d="M0 128C0 92.7 28.7 64 64 64H256h48 16H576c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H320 304 256 64c-35.3 0-64-28.7-64-64V128zm320 0V384H576V128H320zM178.3 175.9c-3.2-7.2-10.4-11.9-18.3-11.9s-15.1 4.7-18.3 11.9l-64 144c-4.5 10.1 .1 21.9 10.2 26.4s21.9-.1 26.4-10.2l8.9-20.1h73.6l8.9 20.1c4.5 10.1 16.3 14.6 26.4 10.2s14.6-16.3 10.2-26.4l-64-144zM160 233.2L179 276H141l19-42.8zM448 164c11 0 20 9 20 20v4h44 16c11 0 20 9 20 20s-9 20-20 20h-2l-1.6 4.5c-8.9 24.4-22.4 46.6-39.6 65.4c.9 .6 1.8 1.1 2.7 1.6l18.9 11.3c9.5 5.7 12.5 18 6.9 27.4s-18 12.5-27.4 6.9l-18.9-11.3c-4.5-2.7-8.8-5.5-13.1-8.5c-10.6 7.5-21.9 14-34 19.4l-3.6 1.6c-10.1 4.5-21.9-.1-26.4-10.2s.1-21.9 10.2-26.4l3.6-1.6c6.4-2.9 12.6-6.1 18.5-9.8l-12.2-12.2c-7.8-7.8-7.8-20.5 0-28.3s20.5-7.8 28.3 0l14.6 14.6 .5 .5c12.4-13.1 22.5-28.3 29.8-45H448 376c-11 0-20-9-20-20s9-20 20-20h52v-4c0-11 9-20 20-20z"/></svg>`,
      action: () => {
        hideDrawerBody = true;
        showLanguageSettings = true;
      },
    },
    // {
    //   id: 3,
    //   label: "Request to call",
    //   icon: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#000000}</style><path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z"/></svg>`,
    //   action: () => {
    //     console.log("Request to call");
    //   },
    // }
  ];

  const headers = { Authorization: "Token " + getAuthKey() };

  // async function fetchData(page_size = 20, search_val) {
  //   chatListLoading = true;
  //   const payload = {
  //     organisation_office_id: parseInt(getPersonOrgOfficeId()),
  //     app_type: "CUSTOMER",
  //     is_partner: false,
  //     is_customer: access_by_type === "customer" ? true : false,
  //     list_type : "all",
  //     page_number : page,
  //     page_size,
  //     status: null,
  //     search_val,
  //   };
  //   const res = await axios.post('https://test.cleandesk.co.in/api/v2/hd/ticket/list',{ ...payload }, { headers })
  //     // .then(response => {
  //     //   chatListLoading = false;
  //     //   newBatch = response.data.rows;
  //     //   chatList = [...chatList, ...newBatch,];
  //     // })
  //     // .catch(error => {
  //     //   console.error(error);
  //     // });
  //       const newBatch = await res.data.rows;
  //       chatList = [...chatList, ...newBatch,];
  //       return chatList;
  // }

  // let fetch_data_promise = fetchData();

  const GetTranslatedText = (subjectType) => {
    axios
      .post(BASIC_AI_URL + "/ai/translation/message", {
        person_id: getPersonId(),
        context_type: "ui",
        subject_type: subjectType,
      })
      .then((response) => {
        // Handle the response data
        // console.log(response.data.rows[0].translated_message);
        translatedText = response.data.rows[0].translated_message;
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  const fetchData = (page_size = 20, search_val) => {
    chatListLoading = true;
    const payload = {
      organisation_office_id: parseInt(getPersonOrgOfficeId()),
      app_type: "CUSTOMER",
      is_partner: false,
      is_customer: access_by_type === "customer" ? true : false,
      list_type: "all",
      page_number: page,
      page_size,
      status: null,
      search_val,
      is_only_tickets: isOnlyTickets,
      is_callback: isCallback,
    };
    axios
      .post(HELPDESK_MODULE_V2 + "/ticket/list", { ...payload }, { headers })
      .then((response) => {
        chatListLoading = false;
        newBatch = response.data.rows;
        chatList = [...chatList, ...newBatch];
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchTicketItem = (ticket_main_id) => {
    chatListLoading = true;
    const payload = {
      app_type: "CUSTOMER",
      id: ticket_main_id,
    };
    axios
      .post(HELPDESK_MODULE + "/ticket/view", { ...payload }, { headers })
      .then((response) => {
        chatListLoading = false;
        chatList = [response.data.rows, ...chatList];
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchList = (searchValue) => {
    page = 1;
    chatList = [];
    newBatch = [];
    searchQuery = searchValue;
    fetchData(20, searchValue);
    // cancelTimeout();
    // timeoutId = setTimeout(() => {
    // }, 1000);
  };

  $: if ($isSocketReset) {
    setupSocketListeners();
  }

  function setupSocketListeners() {
    $chatSocket.emit("join", getPersonId());
    if (access_by_type === "employee") {
      $chatSocket.emit("join_organisation", getPersonOrgOfficeId());
    }
    $chatSocket.on("ticket_details", (data) => {
      const ticketItem = chatList.find((el) => el.id == data.id);

      if (ticketItem) {
        havinesh = {
          ...ticketItem,
          conversation_status: data.conversation_status,
          last_comment: data.last_comment,
          type_of_ticket: data.type_of_ticket,
          current_status_id: data.current_status_id,
          current_status_name: data.current_status_name,
          ticket_id: data.ticket_id,
        };

        pushTicketToTop(havinesh);
      } else {
        fetchTicketItem(parseInt(data.id));
      }
    });
  }

  onMount(() => {
    {
      GetTranslatedText("new_conversation");
    }
    // load first batch onMount
    selectedItem = $selectedConversation;
    fetchData();
  });

  const handleTicketItemClick = (item) => {
    dispatch("selectedItem", item);
    // selectedMessage = item;
    // $chatSocket.emit("mark_conversation", {
    //   ticket_main_id: item.id,
    //   status: "read"
    // });
    selectedConversation.set(item);
    markAsRead(item);
  };

  // const handleNewConverstionClick = (item) => {
  //   dispatch("startNewChat");
  //   selectedMessage = null;
  //   selectedItem = null;
  // };

  let havinesh;

  const pushTicketToTop = (ticketItem) => {
    const itemIndex = chatList.findIndex((el) => el.id === ticketItem.id);

    if (~itemIndex) {
      chatList.splice(itemIndex, 1);
      chatList = [ticketItem, ...chatList];
    }
  };

  const refetchLatestTickets = () => {
    fetchData(chatList.length > 20 ? chatList.length : 20);
  };

  const cancelTimeout = () => {
    clearTimeout(timeoutId);
  };

  const handleSearch = (e) => {
    // searchQuery = e.target.value;
    page = 1;
    // fetchData(20, searchQuery);

    cancelTimeout();
    timeoutId = setTimeout(() => {
      console.log("searching");
      fetchData(20, searchQuery);
    }, 30000);
  };

  const markAsUnread = (item) => {
    $chatSocket.emit("mark_conversation", {
      ticket_main_id: item.id,
      status: "unread",
    });
  };

  const markAsRead = (item) => {
    $chatSocket.emit("mark_conversation", {
      ticket_main_id: item.id,
      status: "read",
    });
  };

  const updateCallbackStatus = (event) => {
    const data = event.detail;

    $chatSocket.emit("update_callback_status", data);
  };

  function handleKeyDown(event) {
    // Stop keyboard event propagation
    event.stopPropagation();
  }
</script>

<!--
<div class="chat-list-container" style="width: {$DeviceType === 'desktop' ? '300px' : '100%'};">

  <div class="chat-header-container">
    <div class="chat-header" style="padding: 12px; display: flex; align-items: center; font-family: sans-serif">
      <button  on:click={()=> {drawerVisible = !drawerVisible;}} style="border: none; background-color: transparent; outline: none; cursor: pointer; margin-right: 4px">
        <svg class="icon-color" xmlns="http://www.w3.org/2000/svg" height="1.3em" viewBox="0 0 448 512" style="margin-right: 4px;"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
      </button>
      <div class="chat-header-avatar" style="display: flex; align-items: center;">
        {#if}
          <img src={DOMAIN + $OrganisationDetails?.logo} alt="Avatar" style="height: 28px; border-radius: 50%; margin-right: 8px;">
        {:else}
          <img src={DOMAIN + /media/default/profile/person/default.png} alt="Avatar" style="height: 28px; border-radius: 50%; margin-right: 8px;">
        {/if}
      </div>
      <div class="cleandesk-header-profile-name custom-text-color">
        {$OrganisationDetails?.title}
      </div>

      <div class="dropdown" style="margin-left: auto">
        {#if access_by_type === 'customer'}
          <button style="border-radius: 50%; background: none; height: 28px; align-items: center! important; justify-content: center; border: none; margin-right: 8px; display: flex;" on:click={handleNewConverstionClick}>
            <svg xmlns="http://www.w3.org/2000/svg" class="custom-text-color" style="height: 20px; width: 20px;" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 16 16">
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
          </button>
        {/if}
      </div>

      <button on:click={() => dispatch('chatListVisible')} style="background: none; border: none; align-items: center; display: flex;
    padding: 1px 8px 1px 2px;">
      <svg class="icon-color" xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
    </button>
  </div>
</div>

-->

<!-- <div style="display: flex;">

  <button class="{selectedFilter === 'all' ? 'secondary-background-color' : ''}" style="width: 50%; border-width: 1px; height: 30px; cursor: pointer;" on:click={() => selectedFilter = 'all'}>All</button>

  <button class="{selectedFilter === 'tickets' ? 'secondary-background-color' : ''}" style="width: 50%; border-width: 1px; height: 30px; cursor: pointer;" on:click={() => selectedFilter = 'tickets'}>Tickets</button>

</div> -->

{#if access_by_type === "customer"}
  {#if $widgetType === "chatbox" || $DeviceType !== "desktop"}
    <div style="display: flex; justify-content: center; margin: 4px 8px;">
      <StartNewConversationButton
        expanded={true}
        extraStyles="margin: 0; width: 60%; padding: 4px;"
        textStyles="font-size: 14px;"
      />
    </div>
  {/if}
{:else}
  <div style="display: flex;">
    <button
      class="chat-list-tab {!isOnlyTickets && !isCallback
        ? 'secondary-background-color'
        : ''}"
      on:click={() => {
        chatList = [];
        newBatch = [];
        page = 1;
        isOnlyTickets = false;
        isCallback = false;
        fetchData();
      }}>All</button
    >

    <button
      class="chat-list-tab {isOnlyTickets ? 'secondary-background-color' : ''}"
      on:click={() => {
        chatList = [];
        newBatch = [];
        page = 1;
        isOnlyTickets = true;
        isCallback = false;
        fetchData();
      }}>Tickets</button
    >

    <button
      class="chat-list-tab {isCallback ? 'secondary-background-color' : ''}"
      on:click={() => {
        chatList = [];
        newBatch = [];
        page = 1;
        isOnlyTickets = false;
        isCallback = true;
        fetchData();
      }}>Call back</button
    >
  </div>
{/if}

<div style="padding: 8px 12px;">
  <!-- <input
    type="text"
    id="myInput"
    placeholder="Search..."
    bind:value={searchQuery}
    on:input={searchList}
    title="searchBar"
    style="{$DeviceType === 'desktop'
      ? 'width: 216px; background-position: 249px 6px;'
      : 'width: calc(100vw - 90px); background-position: 350px 6px;'}; border-radius: 4px;"
    on:keydown={handleKeyDown}
  /> -->
  <SearchMain on:search={(e) => searchList(e.detail)} />

  <!-- <input type="text" id="myInput" placeholder="Search..." bind:value={searchQuery} on:input={handleSearch} title="searchBar"> -->
</div>
<div
  class="chat-list"
  style="height: {$widgetType === 'chatsite'
    ? $DeviceType !== 'desktop'
      ? 'calc(100% - 88.5px)'
      : 'calc(100% - 52.5px)'
    : 'calc(100% - 142.5px)'};"
>
  {#if chatList.length > 0}
    <ul style="margin: 0;">
      {#each chatList as item}
        {#key chatList}
          {#if item?.last_comment !== null}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <li
              class="testing cleandesk-chat-list-item {item?.id ===
                parseInt($selectedConversation?.id) &&
                'secondary-background-color'}"
              on:click={handleTicketItemClick(item)}
              style="padding: 8px 12px; border-bottom: 0.5px solid #d6c7c7; width: 100%;"
            >
              <ChatListItem
                {item}
                {access_by_type}
                {isCallback}
                on:MarkAsUnreadClicked={(item) => markAsUnread(item.detail)}
                on:MarkAsReadClicked={(item) => markAsRead(item.detail)}
                on:ChangeCallbackStatus={updateCallbackStatus}
              />
            </li>
          {/if}
        {/key}
      {/each}
      <InfiniteScroll
        hasMore={newBatch.length}
        threshold={100}
        on:loadMore={() => {
          page++;
          fetchData(20, searchQuery);
        }}
      />
    </ul>
  {:else if chatList.length === 0 && !chatListLoading && access_by_type === "customer"}
    <div
      style="height: 100%; display: flex; flex-direction: column; justify-content: center;"
    >
      <p style="text-align: center; padding: 8px;">
        {$OrganisationDetails.title}
        {" "}
        {translatedText}
      </p>
      <div style="text-align: center;">
        <svg
          style="fill: #000"
          xmlns="http://www.w3.org/2000/svg"
          height="2em"
          viewBox="0 0 512 512"
          ><path
            d="M256 80C149.9 80 62.4 159.4 49.6 262c9.4-3.8 19.6-6 30.4-6c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48c-44.2 0-80-35.8-80-80V384 336 288C0 146.6 114.6 32 256 32s256 114.6 256 256v48 48 16c0 44.2-35.8 80-80 80c-26.5 0-48-21.5-48-48V304c0-26.5 21.5-48 48-48c10.8 0 21 2.1 30.4 6C449.6 159.4 362.1 80 256 80z"
          /></svg
        >
      </div>
    </div>
  {:else if chatListLoading}
    <div class="chat-list-spinner">
      <h3><span class="visually-hidden">visuallyhidden</span></h3>
    </div>
  {/if}
  <!-- {#await fetch_data_promise}
        <div class="chat-list-spinner">
          <h3><span class="visually-hidden">visuallyhidden</span></h3>
        </div>
    {:then}
        <ul style="margin: 0;">
          {#each chatList as item}
            {#if item.last_comment !== null}
              <li class="testing {item?.id === parseInt($selectedConversation?.id) &&'secondary-background-color'}" on:click={handleTicketItemClick(item)} style="padding: 8px 12px; border-bottom: 0.5px solid #d6c7c7; width: 100%;">
              <ChatListItem {item}/>
            </li>
            {/if}
          {/each}
          <InfiniteScroll
            hasMore={newBatch.length}
            threshold={100}
            on:loadMore={() => {page++; fetchData(20, searchQuery)}} />
        </ul>
    {:catch error}
        <p style="color: red">{error}</p>
    {/await} -->
</div>
<!-- </div> -->

<section class="app">
  {#if drawerVisible}
    <div
      class="drawer {$DeviceType === 'desktop'
        ? 'drawer-desktop'
        : 'drawer-mobile'}"
      transition:fly={{ x: 0, easing: circOut }}
    >
      <div class="drawer-header" style="padding: 8px; display: flex;">
        <!-- {#if !hideDrawerBody} -->
        {#if hideDrawerBody}
          <button
            class=""
            on:click={() => (hideDrawerBody = !hideDrawerBody)}
            style="background: none; border: none;"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.2em"
              viewBox="0 0 448 512"
              ><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style
              >
                svg {
                  fill: #000000;
                }
              </style><path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
              /></svg
            >
          </button>
        {/if}
        <button
          class=""
          on:click={() => {
            drawerVisible = !drawerVisible;
            hideDrawerBody = false;
          }}
          style="background: none; border: none; margin-left: auto;"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18px"
            viewBox="0 0 384 512"
            ><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style
            >
              svg {
                fill: #000000;
              }
            </style><path
              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
            /></svg
          >
        </button>
      </div>

      <div class="drawer-body" style="padding: 8px;">
        {#if !hideDrawerBody}
          {#if access_by_type === "customer"}
            <div
              style="display: flex; justify-content: center; margin: 4px 8px;"
            >
              <button
                class="primary-background-color custom-text-color"
                style="    width: 60%;
              border: none;
              padding: 8px;
              border-radius: 4px;
              cursor: pointer; "
              >
                <!-- on:click={handleNewConverstionClick}>New conversation</button -->
              </button>
            </div>
          {/if}

          <div class="drawer-menu">
            <h3 style="margin: 8px 4px;">Menu</h3>
            <div class="menu-items">
              {#each menuItems as item}
                <button class="menu-item-button" on:click={item.action}>
                  <div
                    style="align-items: center; display: flex; font-size: 16px;"
                  >
                    <div style="margin: 0 8px;">{@html item.icon}</div>
                    <div>{item.label}</div>
                  </div>
                </button>
              {/each}
            </div>
          </div>
        {/if}

        {#if showLanguageSettings && hideDrawerBody}
          <SettingsModal />
        {/if}
      </div>

      <div
        class="drawer-footer"
        style="background-color: #E0DEDE; height: 20px padding: 10px; display: flex; align-items: center; position: absolute; width: 100%; bottom: 0;"
      >
        <p style="font-size: 10px; margin: 5px 8px">
          v{AppDefaults.APP_VERSION}
        </p>
        <a
          href={AppDefaults.CLEANDESK_URL}
          style="text-decoration:none; color: #000; display: flex; align-items: center; margin-left: auto"
        >
          <p style="padding: 6px; margin: 0px; font-size: 12px">
            Powered by CleanDesk Ai
          </p>
          <img
            src={DOMAIN + "/logo96tranparent.png?x=10000000"}
            style="height: 24px; margin-right: 8px"
            alt=""
          />
        </a>
      </div>
    </div>
  {/if}
</section>
