import axios from "axios";
import { OrganisationDetails } from "../stores/authStores";
import { DOMAIN } from "../config/api-variables";
import { getAuthKey, logoutClearLocal } from "./cookie/user";

export const logoutUser = () => {
  const headers = { Authorization: "Token " + getAuthKey() };

  axios
    .post(DOMAIN + "/api/v1/rl/logout/", {}, { headers })
    .then((response) => {
      logoutClearLocal();
    })
    .catch((error) => {
      logoutClearLocal();
      // Handle the error
      console.error(error);
    });
};
