<svelte:options tag="cleandesk-demo-mobile" />

<script>
  export let access_by_type;
  export let access_by_id;
  export let isVisible = false;
  export let isMobileDemo = true;

  import FullScreenWidget from "../FullScreenWidget/FullScreenWidget.svelte";
</script>

<div class="cleandesk-ig-page">
  <div class="cleandesk-ig-mobile-phone">
    <div class="cleandesk-ig-screen-phone" style="overflow: hidden;">
      <FullScreenWidget
        {access_by_type}
        {access_by_id}
        {isVisible}
        {isMobileDemo}
      />
    </div>
  </div>
</div>
