<svelte:options tag="cleandesk-socialmediademo" />

<script>
  import { afterUpdate, onMount } from "svelte";
  import { marked } from "marked";
  import DOMPurify from "dompurify";
  import { chatSocket, isSocketReset } from "../../utils/socket/socketConfig";
  import { getPersonId, getPersonOrgOfficeId } from "../../utils/cookie/user";
  import { OrganisationDetails } from "../../stores/authStores";
  import { DOMAIN } from "../../config/api-variables";
  import TimeAgo from "../../common/TimeAgo.svelte";

  import { fade } from "svelte/transition";
  import { slide } from "svelte/transition";
  import { quintOut } from "svelte/easing";

  export let demoType;

  let messageContainer;

  let textareaValue = "";
  let ticketMainId = null;
  let messageList = [];

  const sendSocketData = (value) => {
    $chatSocket.emit("chat_ai_ticket_message_v2", {
      app_type: "CUSTOMER",
      organisation_office_id: getPersonOrgOfficeId(),
      constituency_id: 1,
      ticket_main_id: value?.ticketMainId,
      person_id: getPersonId(),
      content: value?.content,
      is_media_available: null,
      is_location_available: null,
      latitude: null,
      longitude: null,
      locality: null,
      address: null,
      category_id: null,
      required_inputs: null,
      ticket_id: null,
      menu_id: value?.menu_id,
      parent_menu_id: value?.parent_menu_id,
      menu_request_type: value?.menu_request_type,
      required_input_data: value?.required_input_data,
    });
  };

  const startNewConversation = () => {
    sendSocketData();
  };

  $: if ($isSocketReset) {
    setupSocketListeners();
  }

  function setupSocketListeners() {
    $chatSocket.on("connect", () => {
      console.log($chatSocket.connected);
      startNewConversation();
    });
    $chatSocket.on("chat_ai_ticket_message_v2", (data) => {
      console.log(data, "data");
      if (messageList.length === 0 && !ticketMainId) {
        ticketMainId = data.ticket_main_id;

        // added condition so that it does not add the same message again
        if (!messageList.map((item) => item.id).includes(data.id)) {
          messageList = [
            ...messageList,
            {
              id: data.id,
              type: "received",
              created_at: data.created_at,
              message: data.content,
              is_mine: false,
              not_show: true,
            },
          ];
        }
      } else {
        if (ticketMainId == data.ticket_main_id) {
          if (data.person_id !== parseInt(getPersonId())) {
            if (!messageList.map((item) => item.id).includes(data.id)) {
              messageList = [
                ...messageList,
                {
                  id: data.id,
                  type: "received",
                  created_at: data.created_at,
                  message: data.content,
                  is_mine: false,
                },
              ];
            }
          }
        }
      }
      if (messageList.length === 1) {
        $chatSocket.emit("join_ticket", ticketMainId);
      }
    });
  }

  onMount(() => {
    $chatSocket.emit("join", getPersonId());
  });

  function sendMessage() {
    if (!textareaValue) return;
    const value = {
      ticketMainId: ticketMainId,
      content: textareaValue,
      menu_id: null,
      parent_menu_id: null,
      menu_request_type: null,
      required_input_data: null,
    };
    sendSocketData(value);

    messageList = [
      ...messageList,
      {
        id: Math.random(),
        type: "sent",
        message: textareaValue,
        is_mine: true,
        created_at: Date.now(),
      },
    ];
    textareaValue = "";
  }

  function handleKeyDown(event) {
    // Stop keyboard event propagation
    event.stopPropagation();
  }

  afterUpdate(() => {
    if (messageList) finalScrollToBottom(messageContainer);
  });

  $: if (messageList && messageContainer) {
    finalScrollToBottom(messageContainer);
  }

  const finalScrollToBottom = async (node) => {
    node.scroll({ top: node.scrollHeight, behavior: "smooth" });
  };
</script>

{#if demoType === "facebook"}
  <div class="cleandesk-fb-message">
    <div class="cleandesk-fb-container">
      {#if !messageList.length}
        <div class="cleandesk-demo-loading-overlay">
          <div class="cleandesk-demo-loading-spinner"></div>
        </div>
      {/if}
      <div class="cleandesk-fb-message-top">
        <div
          style="display: flex;
        align-items: center;
        gap: 5px;"
        >
          <img
            src={DOMAIN + $OrganisationDetails?.logo}
            alt="profile"
            style="width: 30px; hight: 30px; object-fit: cover; border-radius: 50%"
          />
          <span style="color: white;">{$OrganisationDetails?.title}</span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#e8eaed"
          ><path
            d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
          /></svg
        >
      </div>
      <div class="cleandesk-fb-grid-message" bind:this={messageContainer}>
        {#each messageList as message (message.id)}
          <div
            class="cleandesk-fb-col-message-{message.type}"
            style={message?.not_show && "display: none"}
          >
            {#if message.type === "received"}
              <img
                src={DOMAIN + $OrganisationDetails?.logo}
                alt="profile"
                style=" width: 25px; hight: 25px; object-fit: cover; border-radius: 50%"
              />
            {/if}
            <div class="cleandesk-fb-message-{message.type}">
              <!-- {@html message.message} -->
              {@html DOMPurify.sanitize(marked(message.message))}
            </div>
          </div>
        {/each}
      </div>
      <div class="cleandesk-fb-message-box">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18px"
            viewBox="0 -960 960 960"
            width="18px"
            fill="dodgerblue"
            ><path
              d="M440-280h80v-160h160v-80H520v-160h-80v160H280v80h160v160Zm40 200q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
            /></svg
          >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18px"
            viewBox="0 -960 960 960"
            width="18px"
            fill="dodgerblue"
            ><path
              d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm40-80h480L570-480 450-320l-90-120-120 160Zm-40 80v-560 560Z"
            /></svg
          >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18px"
            viewBox="0 -960 960 960"
            width="18px"
            fill="dodgerblue"
            ><path
              d="M340-400h40q17 0 28.5-11.5T420-440v-40h-40v40h-40v-80h80q0-17-11.5-28.5T380-560h-40q-17 0-28.5 11.5T300-520v80q0 17 11.5 28.5T340-400Zm120 0h40v-160h-40v160Zm80 0h40v-60h60v-40h-60v-20h80v-40H540v160ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"
            /></svg
          >
        </div>
        <form on:submit|preventDefault={sendMessage}>
          <input
            class="cleandesk-fb-message-input"
            placeholder="Aa"
            type="text"
            style="border: none; background-color: rgb(233, 233, 233); padding: 4px 14px;
        border-radius: 20px;"
            bind:value={textareaValue}
            on:keydown={handleKeyDown}
          />
        </form>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height="18px"
            width="18px"
            fill="dodgerblue"
            ><path
              d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"
            /></svg
          >
        </div>
      </div>
    </div>
  </div>
{/if}

{#if demoType === "instagram"}
  <div class="cleandesk-ig-page">
    <div class="cleandesk-ig-mobile-phone">
      <div class="cleandesk-ig-screen-phone">
        {#if !messageList.length}
          <div class="cleandesk-demo-loading-overlay">
            <div class="cleandesk-demo-loading-spinner"></div>
          </div>
        {/if}
        <div class="cleandesk-ig-screen-container">
          <div class="cleandesk-ig-chat">
            <div class="cleandesk-ig-chat-container">
              <div class="cleandesk-ig-user-bar">
                <div class="cleandesk-ig-back">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#000000"
                    ><path
                      d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"
                    /></svg
                  >
                </div>
                <div class="cleandesk-ig-avatar">
                  <img
                    src={DOMAIN + $OrganisationDetails?.logo}
                    alt="Avatar"
                    style="border-radius: 50%;"
                  />
                </div>
                <div class="cleandesk-ig-name">
                  <span>{$OrganisationDetails?.title}</span>
                </div>
                <div style="display: flex; gap: 15px; margin-left: 20px;">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#000000"
                    ><path
                      d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h480q33 0 56.5 23.5T720-720v180l160-160v440L720-420v180q0 33-23.5 56.5T640-160H160Zm0-80h480v-480H160v480Zm0 0v-480 480Z"
                    /></svg
                  >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#000000"
                    ><path
                      d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z"
                    /></svg
                  >
                </div>
              </div>
              <div class="cleandesk-ig-conversation">
                <div
                  class="cleandesk-ig-conversation-container"
                  bind:this={messageContainer}
                >
                  <div class="cleandesk-ig-profile-preview">
                    <img
                      src={DOMAIN + $OrganisationDetails?.logo}
                      alt="Avatar"
                      style="width: 80px; height: 80px; border-radius: 50%;"
                    />
                    <p
                      style="font-size: 16px; font-weight: 600; text-align: center;"
                    >
                      {$OrganisationDetails?.title}
                    </p>
                    <p style="font-size: 10px;">
                      {$OrganisationDetails?.title
                        .toLowerCase()
                        .replace(/ /g, "_")}
                    </p>
                    <div
                      style="width: fit-content; margin-top: 5px; font-size: 12px; background-color: rgb(233, 233, 233); border-radius: 5px; padding: 2px 4px;
                        "
                    >
                      View profile
                    </div>
                  </div>
                  {#each messageList as message (message.id)}
                    <div
                      class="cleandesk-ig-message cleandesk-ig-{message.type}"
                      style={message?.not_show && "display: none"}
                    >
                      <!-- {@html message.message} -->
                      {@html DOMPurify.sanitize(marked(message.message))}
                    </div>
                  {/each}
                </div>
                <form
                  class="cleandesk-ig-conversation-compose"
                  on:submit|preventDefault={sendMessage}
                >
                  <div class="cleandesk-ig-cam">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      height="15px"
                      width="15px"
                      fill="#ffffff"
                      ><path
                        d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"
                      /></svg
                    >
                  </div>
                  <input
                    class="cleandesk-ig-input-msg"
                    style=" background: transparent;
                      height: 100%;"
                    name="input"
                    placeholder="Message..."
                    autocomplete="off"
                    bind:value={textareaValue}
                    on:keydown={handleKeyDown}
                  />
                  <div class="cleandesk-ig-photo">
                    {#if !textareaValue}
                      <div
                        transition:fade={{ delay: 150, duration: 200 }}
                        style="display: flex;
                      justify-content: center;
                      align-items: center;"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="18px"
                          viewBox="0 -960 960 960"
                          width="18px"
                          fill="#000000"
                          ><path
                            d="M480-400q-50 0-85-35t-35-85v-240q0-50 35-85t85-35q50 0 85 35t35 85v240q0 50-35 85t-85 35Zm0-240Zm-40 520v-123q-104-14-172-93t-68-184h80q0 83 58.5 141.5T480-320q83 0 141.5-58.5T680-520h80q0 105-68 184t-172 93v123h-80Zm40-360q17 0 28.5-11.5T520-520v-240q0-17-11.5-28.5T480-800q-17 0-28.5 11.5T440-760v240q0 17 11.5 28.5T480-480Z"
                          /></svg
                        >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="18px"
                          viewBox="0 -960 960 960"
                          width="18px"
                          fill="#000000"
                          ><path
                            d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm40-80h480L570-480 450-320l-90-120-120 160Zm-40 80v-560 560Zm140-360q25 0 42.5-17.5T400-620q0-25-17.5-42.5T340-680q-25 0-42.5 17.5T280-620q0 25 17.5 42.5T340-560Z"
                          /></svg
                        >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="18px"
                          viewBox="0 -960 960 960"
                          width="18px"
                          fill="#000000"
                          ><path
                            d="M200-200h360v-200h200v-360H200v560Zm0 80q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v400L600-120H200Zm80-280v-80h200v80H280Zm0-160v-80h400v80H280Zm-80 360v-560 560Z"
                          /></svg
                        >
                      </div>
                    {/if}
                    {#if textareaValue}
                      <div
                        transition:slide={{
                          delay: 250,
                          duration: 300,
                          easing: quintOut,
                          axis: "x",
                        }}
                      >
                        <button
                          style=" display: flex;
                          align-items: center;
                          justify-content: center;
                          background: #434fff;
                          border-radius: 16px;
                          margin-left: 8px;
                          width: 40px;
                          height: 25px;
                          padding: 1px;"
                          type="submit"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            height="14px"
                            width="14px"
                            fill="#ffffff"
                            ><path
                              d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"
                            /></svg
                          >
                        </button>
                      </div>
                    {/if}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

{#if demoType === "whatsapp"}
  <div class="cleandesk-wa-page">
    <div class="cleandesk-wa-mobile-phone">
      <div class="cleandesk-wa-screen-phone">
        {#if !messageList.length}
          <div class="cleandesk-demo-loading-overlay">
            <div class="cleandesk-demo-loading-spinner"></div>
          </div>
        {/if}
        <div class="cleandesk-wa-screen-container">
          <div class="cleandesk-wa-chat">
            <div class="cleandesk-wa-chat-container">
              <div class="cleandesk-wa-user-bar">
                <div class="cleandesk-wa-back">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#e8eaed"
                    ><path
                      d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"
                    /></svg
                  >
                </div>
                <div class="cleandesk-wa-avatar">
                  <img
                    src={DOMAIN + $OrganisationDetails?.logo}
                    alt="Avatar"
                    style="border-radius: 50%;
                    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
                    display: block;
                    width: 100%;"
                  />
                </div>
                <div class="cleandesk-wa-name">
                  <span>{$OrganisationDetails?.title}</span>
                  <span class="cleandesk-wa-status">online</span>
                </div>
                <div class="cleandesk-wa-actions more">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#e8eaed"
                    ><path
                      d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h480q33 0 56.5 23.5T720-720v180l160-160v440L720-420v180q0 33-23.5 56.5T640-160H160Zm0-80h480v-480H160v480Zm0 0v-480 480Z"
                    /></svg
                  >
                </div>
                <div class="cleandesk-wa-actions attachment">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#e8eaed"
                    ><path
                      d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z"
                    /></svg
                  >
                </div>
                <div class="cleandesk-wa-actions">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20px"
                    viewBox="0 -960 960 960"
                    width="20px"
                    fill="#e8eaed"
                    ><path
                      d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z"
                    /></svg
                  >
                </div>
              </div>
              <div class="cleandesk-wa-conversation">
                <div
                  class="cleandesk-wa-conversation-container"
                  bind:this={messageContainer}
                >
                  {#each messageList as message (message.id)}
                    <div
                      class="cleandesk-wa-message cleandesk-wa-{message.type}"
                      style={message?.not_show && "display: none"}
                    >
                      <!-- {@html message.message} -->
                      {@html DOMPurify.sanitize(marked(message.message))}

                      <span class="cleandesk-wa-metadata">
                        <span class="cleandesk-wa-time"
                          ><TimeAgo timestamp={message?.created_at} /></span
                        >
                        {#if message.type === "sent"}
                          <span class="cleandesk-wa-tick">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="15"
                              id="msg-dblcheck-ack"
                              x="2063"
                              y="2076"
                              ><path
                                d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                fill="#4fc3f7"
                              /></svg
                            >
                          </span>
                        {/if}
                      </span>
                    </div>
                  {/each}
                </div>
                <form
                  class="cleandesk-wa-conversation-compose"
                  on:submit|preventDefault={sendMessage}
                >
                  <div class="cleandesk-wa-emoji">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      id="smiley"
                      x="3147"
                      y="3209"
                      ><path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.153 11.603c.795 0 1.44-.88 1.44-1.962s-.645-1.96-1.44-1.96c-.795 0-1.44.88-1.44 1.96s.645 1.965 1.44 1.965zM5.95 12.965c-.027-.307-.132 5.218 6.062 5.55 6.066-.25 6.066-5.55 6.066-5.55-6.078 1.416-12.13 0-12.13 0zm11.362 1.108s-.67 1.96-5.05 1.96c-3.506 0-5.39-1.165-5.608-1.96 0 0 5.912 1.055 10.658 0zM11.804 1.01C5.61 1.01.978 6.034.978 12.23s4.826 10.76 11.02 10.76S23.02 18.424 23.02 12.23c0-6.197-5.02-11.22-11.216-11.22zM12 21.355c-5.273 0-9.38-3.886-9.38-9.16 0-5.272 3.94-9.547 9.214-9.547a9.548 9.548 0 0 1 9.548 9.548c0 5.272-4.11 9.16-9.382 9.16zm3.108-9.75c.795 0 1.44-.88 1.44-1.963s-.645-1.96-1.44-1.96c-.795 0-1.44.878-1.44 1.96s.645 1.963 1.44 1.963z"
                        fill="#7d8489"
                      /></svg
                    >
                  </div>
                  <input
                    class="cleandesk-wa-input-msg"
                    style="background: #fff;
                    height: 100%;"
                    name="input"
                    placeholder="Type a message"
                    autocomplete="off"
                    bind:value={textareaValue}
                    on:keydown={handleKeyDown}
                  />
                  <div class="cleandesk-wa-photo">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#e8eaed"
                      ><path
                        d="M480-260q75 0 127.5-52.5T660-440q0-75-52.5-127.5T480-620q-75 0-127.5 52.5T300-440q0 75 52.5 127.5T480-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM160-120q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h126l74-80h240l74 80h126q33 0 56.5 23.5T880-680v480q0 33-23.5 56.5T800-120H160Zm0-80h640v-480H638l-73-80H395l-73 80H160v480Zm320-240Z"
                      /></svg
                    >
                  </div>
                  <button class="cleandesk-wa-send" type="submit">
                    <div class="cleandesk-wa-circle">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="#e8eaed"
                        ><path
                          d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z"
                        /></svg
                      >
                    </div>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
