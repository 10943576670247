<svelte:options tag="chat-header" />

<script>
  export let name;
  export let avatar;
  export let access_by_type;
  export let access_by_id;
  export let id = null;
  export let isChatListVisible = false;

  import { createEventDispatcher } from "svelte";
  import { DOMAIN } from "../../../config/api-variables";
  import { onMount } from "svelte";
  import { DeviceType } from "../../../stores/authStores";
  import { generateColorFromNumber } from "../../../utils/tools/data-transformers";
  import { startNewAiConversation } from "../../../stores/chatStores";
  const dispatch = createEventDispatcher();

  let avatarColor;
  let avatarLetter;
  let selectedMessageId;

  onMount(() => {
    if (access_by_type === "employee") {
      selectedMessageId = id;
      avatarLetter = name?.charAt(0).toUpperCase();
      avatarColor = generateColorFromNumber(selectedMessageId);
    }
  });
</script>

<div class="chat-header-container">
  <div
    class="chat-header"
    style="padding: 12px; display: flex; align-items: center; font-family: sans-serif; height: 1.4rem"
  >
    <div class="chat-header-avatar" style="display: flex; align-items: center;">
      {#if access_by_type === "employee"}
        <div
          class="avatar"
          style="background-color: {avatarColor}; width: 24px;height: 24px;display: flex;align-items: center;justify-content: center;border-radius: 50%;color: white;font-size: 12px; margin-right: 8px"
        >
          {avatarLetter}
        </div>
      {:else if avatar}
        <img
          src={DOMAIN + avatar}
          alt="Avatar"
          style="height: 28px; margin-right: 8px;"
        />
      {:else}
        <img
          src={DOMAIN + "/media/default/profile/person/default.png"}
          alt="Avatar"
          style="height: 28px; margin-right: 8px;"
        />
      {/if}
    </div>
    <div class="cleandesk-header-profile-name custom-text-color">
      {name?.toLowerCase()}
    </div>
    <div style="display: flex; margin-left: auto;">
      <button
        on:click={() => startNewAiConversation.set({ startNew: true })}
        style="background: none; border: none; display: flex; align-items: center; margin: 0 0 0 auto"
      >
        <svg
          class="icon-color"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
          ><path
            d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"
          /></svg
        >
      </button>
      {#if access_by_type === "customer" && access_by_id}
        <button
          on:click={() => dispatch("chatListVisible")}
          style="background: none; border: none; display: flex; align-items: center; margin: 0 0 0 auto"
        >
          {#if isChatListVisible}
            <svg
              class="icon-color"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              ><path
                d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"
              /></svg
            >
          {:else}
            <svg
              class="icon-color"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              ><path
                d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z"
              /></svg
            >
          {/if}
        </button>
      {/if}
      <button
        on:click={() => dispatch("closeWidget")}
        style="background: none; border: none; display: flex; align-items: center; margin: 0 0 0 auto"
      >
        <svg
          class="icon-color"
          xmlns="http://www.w3.org/2000/svg"
          height="18px"
          viewBox="0 0 384 512"
          ><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
          /></svg
        >
      </button>
    </div>
  </div>
</div>
