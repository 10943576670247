import axios from "axios";
import { DeviceType } from "../stores/authStores";
import { LEGISLATOR_MANAGEMENT_MODULE } from "../config/api-variables";
import { getAuthKey, getPersonOrgOfficeId } from "./cookie/user";
import { listingMenuItems, selectedListingMenuItem } from "../stores/menuStores";
import { alertMessageService } from "../common/AlertMessage/alertMessageService";

export const FetchListingMenus = () => {
  let deviceType;

  // Subscribe to the DeviceType store
  DeviceType.subscribe(value => {
    deviceType = value;
  })();

  const headers = { Authorization: "Token " + getAuthKey() };

  const payload = {
    organisation_office_id: parseInt(getPersonOrgOfficeId()),
  }

  axios
    .post(LEGISLATOR_MANAGEMENT_MODULE + "/list-menu/visible/list", payload, { headers })
    .then((response) => {
      if (response.data.statusCode === "S10001") {
        let menuItems = response.data.rows;

        if (deviceType === "mobile") {
          if (menuItems.length > 0) {
            menuItems.unshift({
              id: 'chat',
              name: "Chat",
            });
          }
        }
        listingMenuItems.set(menuItems);
        selectedListingMenuItem.set(menuItems[0]);
      } else {
        listingMenuItems.set([]);
        selectedListingMenuItem.set(null);
        alertMessageService.error(response.data.message);
      }
    })
    .catch((error) => {
      // Handle the error
      console.error(error);
    });
};
