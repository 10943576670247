<svelte:options tag="callback-status-modal" />

<script>
  import axios from "axios";
  import { createEventDispatcher, onMount } from "svelte";
  import {
    getAuthKey,
    getPersonOrgOfficeId,
  } from "../../../../utils/cookie/user";
  import { STANDARD_DICTIONARIES_MODULE } from "../../../../config/api-variables";

  export let showModal; // boolean

  let dialog; // HTMLDialogElement

  const dispatch = createEventDispatcher();

  $: if (dialog && showModal) dialog.showModal();

  let selectedStatus = "";

  let statusList = [];

  const fetchCallbackStatusList = () => {
    const headers = { Authorization: "Token " + getAuthKey() };
    const payload = {
      organisation_office_id: getPersonOrgOfficeId(),
    };
    axios
      .post(
        STANDARD_DICTIONARIES_MODULE + "/callback/status",
        { ...payload },
        { headers }
      )
      .then((response) => {
        statusList = response.data.rows;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // onMount(() => {
  //   fetchCallbackStatusList();
  // })
  $: if (showModal) {
    fetchCallbackStatusList();
  }

  const changeCallbackStatus = () => {
    if (selectedStatus) {
      const data = {
        selectedStatus: selectedStatus,
      };
      dispatch("selectedStatusData", data);
      dialog.close();
    } else {
      alert("Please select callback status");
    }
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events a11y-no-noninteractive-element-interactions -->
<dialog
  bind:this={dialog}
  on:close={() => (showModal = false)}
  on:click|self={() => dialog.close()}
>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div style="display: flex; flex-direction: column;" on:click|stopPropagation>
    <div>
      <p style="margin: 0 0 14px 0; font-weight: 600; font-size: 20px;">
        Change callback status
      </p>

      <div style="margin: 14px 0; font-size: 14px;">
        {#each statusList as item (item.id)}
          <div style="display: flex; align-items: center; padding: 4px 0;">
            <label style="margin-right: 10px;" for={`checkbox-${item.id}`}
              >{item.callback_name}</label
            >
            <input
              id={`checkbox-${item.id}`}
              style="margin-left: auto;"
              type="checkbox"
              checked={selectedStatus === item.callback_name}
              on:change={() => (selectedStatus = item.callback_name)}
            />
          </div>
          <hr />
        {/each}
      </div>
    </div>

    <div style="display: flex; justify-content: end;">
      <button
        style="display: block; margin: 4px; padding: 8px; background: #f3f2f2; border: none; cursor: pointer; border-radius: 8px"
        on:click={() => dialog.close()}>Close</button
      >
      <button
        class="primary-background-color custom-text-color"
        style="display: block; margin: 4px; padding: 8px; border: none; cursor: pointer; border-radius: 8px"
        on:click={() => changeCallbackStatus()}>Submit</button
      >
    </div>
  </div>
</dialog>
