import axios from "axios";
import {
  OrganisationDetails,
  WidgetUiDetails,
  isShareLink,
} from "../stores/authStores";
import { DOMAIN } from "../config/api-variables";
import { removeHttpHttpsWww } from "./tools/data-transformers";
import { getPersonOrgOfficeId, removeNonAuthConversationId } from "./cookie/user";
import { logoutUser } from "./LogOutUser";

export const getOfficeDetails = (app_id) => {
  let payload = {};

  // Check conditions and modify payload accordingly
  if (app_id) {
    payload.client_id = app_id;
  } else if (isShareLink) {
    const slugName = window.location.pathname.split("/")[2];
    payload.slug_name = slugName;
  } else {
    payload.client_domain = removeHttpHttpsWww(window.location.hostname);
  }

  axios
    .post(DOMAIN + "/api/v1/organisation/widget-ui/view", payload)
    .then((response) => {
      // Handle the response data
      const logo = response.data?.rows.widget_logo
        ? response.data.rows?.widget_logo
        : response.data.rows?.logo;

      const brand_name = response.data.rows?.brand_name
        ? response.data.rows?.brand_name
        : response.data.rows?.title;

      OrganisationDetails.set({
        logo: logo,
        primary_color: response.data.rows?.primary_color,
        title: brand_name,
        home_page_banner_urls: response.data.rows?.home_page_banner_urls,
        slogan: response.data.rows?.slogan,
      });

      WidgetUiDetails.set(response.data.rows);

      const personOrgOfficeId = getPersonOrgOfficeId();

      if (personOrgOfficeId) {
        const parsedPersonOrgOfficeId = parseInt(personOrgOfficeId, 10);

        // Check if parsedPersonOrgOfficeId is a valid number
        if (!isNaN(parsedPersonOrgOfficeId) && parsedPersonOrgOfficeId !== response.data.rows?.organisation_office_id) {
          logoutUser();
          removeNonAuthConversationId();
        }
      }
    })
    .catch((error) => {
      // Handle the error
      console.error(error);
    });
};
