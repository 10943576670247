<svelte:options tag="cleandesk-full-screen-page-window" />

<script>
  import { alertMessageService } from "../../../common/AlertMessage/alertMessageService";
  import {
    DOMAIN,
    LEGISLATOR_MANAGEMENT_MODULE,
  } from "../../../config/api-variables";
  import { getAuthKey } from "../../../utils/cookie/user";
  import axios from "axios";
  import { selectedMenuItem } from "../../../stores/menuStores";
  import el from "date-fns/locale/el";

  let pageViewData;

  let pageViewImg = null;

  $: if ($selectedMenuItem) fetchPageView();

  const fetchPageView = () => {
    const headers = { Authorization: "Token " + getAuthKey() };

    axios
      .post(
        LEGISLATOR_MANAGEMENT_MODULE + "/menu/page/view",
        { menu_id: $selectedMenuItem?.id },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          pageViewData = response.data.rows?.content;
          pageViewImg = response.data.rows?.media_url;
        }
        if (response.data.statusCode === "E100001")
          alertMessageService.error(response.data.message);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };
</script>

<div style="height: 100%; overflow: auto;">
  {#if pageViewImg}
    <img src={DOMAIN + pageViewImg} alt="banner" />
  {/if}
  {#if pageViewData}
    <div style="padding: 0 5%">
      {@html pageViewData}
    </div>
  {:else}
    <div
      style="height: 100%; display: flex; justify-content: center; align-items: center;"
    >
      <h4 style="margin: 0;">Coming soon!</h4>
    </div>
  {/if}
</div>
