<svelte:options tag="chat-widget-container" />

<!-- <svelte:head>
  <script src="//speedof.me/api/api.js" type="text/javascript"></script>
</svelte:head> -->

<script lang="js">
  export let isVisible;
  export let access_by_type;
  export let access_by_id;

  import { onMount } from "svelte";
  import {
    getAuthKey,
    getNonAuthConversationId,
    getPersonId,
    getPersonOrgOfficeId,
    setNonAuthConversationId,
    removeNonAuthConversationId,
  } from "../../utils/cookie/user";
  import {
    DeviceType,
    OrganisationDetails,
    userDetails,
  } from "../../stores/authStores";
  import {
    BASIC_AI_URL,
    CHAT_DOMAIN,
    DOMAIN,
    HELPDESK_MODULE,
  } from "../../config/api-variables";
  import ChatHeader from "./components/ChatHeader.svelte";
  import axios from "axios";
  // import ChatListing from "./components/ChatListing/ChatListing.svelte";
  import { createEventDispatcher } from "svelte";
  import MessageItem from "./components/MessageItem.svelte";
  import { AppDefaults } from "../../config/global-enums.js";
  import MessageJson from "./components/MessageJson.svelte";
  import SettingsModal from "./components/SettingsModal.svelte";
  import { PlaySound } from "../../utils/PlaySound";
  import InfiniteScroll from "../../common/InfiniteScroll.svelte";
  import { chatSocket } from "../../utils/socket/socketConfig";
  import {
    StartSessionCountApi,
    EndSessionCountApi,
  } from "../../utils/SessionCountApi";

  // import RightSideWindow from "../RightSideWindow/RightSideWindow.svelte";
  import RightSideWindow from "./components/RightSideWindow/RightSideWindow.svelte";
  import MobileBottomSheet from "../../common/components/MobileBottomSheet.svelte";
  import {
    messages,
    callbackDisabled,
    startNewAiConversation,
    disableInput,
    messageLoading,
    ticketMainId,
    disableMessageLoading,
    isSessionTimerActive,
    sessionTimeoutId,
    hasUserSentMessage,
    isSupportListVisible,
    oldTicketMainId,
    aiMessageLoading,
  } from "../../stores/chatStores";
  import ChatWindow from "../../common/ChatWindow/ChatWindow.svelte";
  import { getRandomSuggestionBgColor } from "../../utils/tools/data-transformers";
  import { selectedMenuItem } from "../../stores/menuStores";
  import { FetchListingMenus } from "../../utils/FetchListingMenus";
  import LoginModal from "../FullScreenWidget/components/LoginModal.svelte";

  $: console.log(messages, "messages");

  const dispatch = createEventDispatcher();

  let loginModalVisible = false;

  let isHelpTextVisible = false;
  let suggestionText = [];

  let textareaValue = "";

  let isChatListVisible = false;
  let selectedMessage = null;

  let menuSuggestionId = null;

  // let messages = [];
  // let messageLoading.set(true);
  // let disablemessageLoading.set(false);

  // let hasUserSentMessage = false;

  // let disableInput.set(false);
  // let ticketMainId = null;
  // let textareaValue = "";
  let page = 1;
  let totalMessages = 0;
  let newBatch = [];

  // let speedTestData = [];
  // let selectedRating = 0;

  // let scroll = 0;
  let isChatWidgetVisible = false;
  // let isChatListVisible = false;
  let isSettingVisible = false;

  // let isHelpTextVisible = false;
  // let suggestionText = [];

  // let selectedMessage = null;

  // let chatContainer;

  // let scrollTop = 0;
  // let scrollHeight = 0;
  // let clientHeight = 0;

  // let timeoutId;
  // let requiredInputs = [];
  // let aiDetails = {};

  // let downloadSpeed;
  // let uploadSpeed;

  let listOfUsers = [];
  // let uniqueUserIds = [];

  // let openSettingsCard = true;

  let conversationStatus = "open";

  // // let callbackDisabled.set(true);

  // let timeoutCount = 0;
  // let secondTimeout;
  // let busySystemErrorMessage = "";
  // let delayErrorMessage = "";

  // let isTabActive = true;

  // let sessionTimeoutId;
  // let isSessionTimerActive;

  let selectedMenu;

  $: {
    if (isVisible && $DeviceType === "desktop") {
      isChatWidgetVisible = isVisible;
      isChatListVisible = isVisible;
      // if (access_by_id) {
      //   isChatListVisible = isVisible;
      // } else {
      //   isChatListVisible = false;
      // }
    }
    if (isVisible && $DeviceType === "mobile") {
      isChatWidgetVisible = isVisible;
    }
  }

  $: {
    if (!isChatWidgetVisible && !isChatListVisible) {
      widgetClosed();
    }
  }

  $: {
    if ($messageLoading === false) {
      fetchSuggestionsList();
    } else {
      isHelpTextVisible = false;
      suggestionText = [];
    }
  }

  const widgetClosed = () => {
    dispatch("closeWidget", true);
    ticketMainId.set(null);
    oldTicketMainId.set(null);
    messageLoading.set(true);
    // page = 1;
    // selectedRating = 0;
    // timeoutCount = 0;
    messages.set([]);
    selectedMessage = null;
    isHelpTextVisible = false;
    suggestionText = [];
    disableInput.set(false);
    // conversationStatus = "open";
    callbackDisabled.set(true);
    hasUserSentMessage.set(false);
    // hasUserSentMessage = false;
  };

  // $: {
  //   if (hasUserSentMessage) {
  //     StartSessionCountApi();
  //   } else {
  //     EndSessionCountApi();
  //   }
  // }

  // const handleVisibilityChange = () => {
  //   if (document.hidden && !isSessionTimerActive) {
  //     isSessionTimerActive = true;
  //     sessionTimeoutId = setTimeout(() => {
  //       hasUserSentMessage = false;
  //     }, 900000);
  //   }
  // };

  const clearSessionTimeout = () => {
    $isSessionTimerActive = false;
    clearTimeout($sessionTimeoutId);
  };

  // const startNewConversation = () => {
  //   messageLoading.set(true);
  //   page = 1;
  //   selectedRating = 0;
  //   messages = [];
  //   hasUserSentMessage = false;
  //   selectedMessage = null;
  //   isChatWidgetVisible = true;
  //   isHelpTextVisible = false;
  //   suggestionText = [];
  //   disableInput.set(false);
  //   timeoutCount = 0;
  //   conversationStatus = "open";
  //   callbackDisabled.set(true);
  //   $chatSocket.emit("chat_ai_ticket_message_v2", {
  //     app_type: "CUSTOMER",
  //     organisation_office_id: getPersonOrgOfficeId(),
  //     constituency_id: 1,
  //     ticket_main_id: null,
  //     person_id: getPersonId(),
  //     content: null,
  //     is_media_available: null,
  //     is_location_available: null,
  //     latitude: null,
  //     longitude: null,
  //     locality: null,
  //     address: null,
  //     category_id: null,
  //     required_inputs: null,
  //     ticket_id: null,
  //   });
  //   if ($DeviceType === "mobile") {
  //     isChatListVisible = false;
  //   }
  // };

  onMount(() => {
    // document.addEventListener("visibilitychange", handleVisibilityChange);
    FetchListingMenus();
  });

  const downloadSpeedTestStart = () => {
    const clientStartTime = new Date().getTime(); // Capture client-side start time
    const xhr = new XMLHttpRequest();
    xhr.open("GET", `${BASIC_AI_URL}/ai/user/speed/download`, true);
    xhr.responseType = "arraybuffer";

    xhr.onload = function () {
      const clientEndTime = new Date().getTime(); // Capture client-side end time
      const downloadData = xhr.response;
      const downloadDataSizeMbit =
        (downloadData.byteLength * 8) / (1024 * 1024); // Convert bytes to Megabit
      const clientDownloadTime = (clientEndTime - clientStartTime) / 1000; // Calculate download time at client side in seconds

      // Calculate download speed in Mbps
      const downloadSpeedMbps = downloadDataSizeMbit / clientDownloadTime;

      console.log("Download Test Results:");
      console.log(`Client Start Time: ${new Date(clientStartTime).toString()}`);
      console.log(`Client End Time: ${new Date(clientEndTime).toString()}`);
      console.log(
        `Download Data Size: ${downloadDataSizeMbit.toFixed(2)} Megabits`,
      );
      console.log(
        `Client Download Time: ${clientDownloadTime.toFixed(2)} seconds`,
      );
      console.log(`Download Speed: ${downloadSpeedMbps.toFixed(2)} Mbps`);

      downloadSpeed = downloadSpeedMbps.toFixed(2);

      uploadSpeedTestStart();
    };

    xhr.onerror = function (error) {
      console.error("Download Test Error:", error);
    };

    xhr.send();
  };

  const uploadSpeedTestStart = () => {
    const clientStartTime = new Date().getTime(); // Capture client-side start time
    const testFile = new Uint8Array(10 * 1024 * 1024).fill(0); // Create a 10MB test file

    const formData = new FormData();
    formData.append("file", new Blob([testFile]), "test_file.txt");

    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${BASIC_AI_URL}/ai/user/speed/upload`, true);

    xhr.onload = function () {
      const clientEndTime = new Date().getTime(); // Capture client-side end time
      const uploadSpeedInfo = JSON.parse(xhr.responseText).rows;

      const uploadSpeedMbps = uploadSpeedInfo.upload_speed;
      const uploadTime = uploadSpeedInfo.upload_time;
      const uploadDataSizeMbit = uploadSpeedInfo.upload_data_size_mbit;
      const serverStartTime = uploadSpeedInfo.server_start_time;
      const serverEndTime = uploadSpeedInfo.server_end_time;

      const clientUploadTime = (clientEndTime - clientStartTime) / 1000;
      const clientUploadSpeed =
        uploadSpeedInfo.upload_data_size_mbit / clientUploadTime;

      console.log("Upload Test Results:");
      console.log(`Client Start Time: ${new Date(clientStartTime).toString()}`);
      console.log(`Client End Time: ${new Date(clientEndTime).toString()}`);
      console.log(`Server Start Time: ${serverStartTime}`);
      console.log(`Server End Time: ${serverEndTime}`);
      console.log(
        `Upload Data Size: ${uploadDataSizeMbit.toFixed(2)} Megabits`,
      );
      console.log(`Upload Time: ${uploadTime.toFixed(2)} seconds`);
      console.log(`Upload Speed: ${uploadSpeedMbps.toFixed(2)} Mbps`);

      console.log(`Client Upload Time: ${clientUploadTime} seconds`);
      console.log(`Client Upload Speed: ${clientUploadSpeed} Mbps`);

      uploadSpeed = clientUploadSpeed.toFixed(2);
      sendSpeedTest();
    };

    xhr.onerror = function (error) {
      console.error("Upload Test Error:", error);
    };

    xhr.send(formData);
  };

  const sendMessage = () => {
    if (textareaValue !== "" && $messageLoading === false) {
      $chatSocket.emit("chat_ai_ticket_message_v2", {
        app_type: "CUSTOMER",
        organisation_office_id: getPersonOrgOfficeId(),
        constituency_id: 1,
        ticket_main_id: $ticketMainId,
        person_id: getPersonId(),
        content: textareaValue,
        is_media_available: null,
        is_location_available: null,
        latitude: null,
        longitude: null,
        locality: null,
        address: null,
        category_id: null,
        required_inputs: null,
        ticket_id: null,
        menu_id: $selectedMenuItem?.id,
        parent_menu_id: $selectedMenuItem?.parent_menu_id,
      });
      hasUserSentMessage.set(true);
      clearSessionTimeout();
      messages.update((value) => [
        {
          content: textareaValue,
          person_id: parseInt(getPersonId()),
          title: $userDetails?.first_name + " " + $userDetails?.last_name,
          person_avatar: $userDetails?.profile_image,
          id: new Date().getTime(),
          created_at: new Date().getTime(),
        },
        ...value,
      ]);
      textareaValue = "";
      if (!$disableMessageLoading) {
        messageLoading.set(true);
        aiMessageLoading.set(true);
      }
      isHelpTextVisible = false;
      suggestionText = [];
      PlaySound(
        "https://testcdn.cleandesk.co.in/sounds/send-message-sound.mp3",
      );
    }
  };

  function scrollToTop() {
    if (chatContainer) {
      chatContainer.scrollTop = 0; // Set scrollTop to 0 to scroll to the top
      // chatContainer.scrollTo({
      //   top: 0,
      //   behavior: 'smooth'
      // }) // Set scrollTop to 0 to scroll to the top
    }
  }

  const fetchSuggestionsList = () => {
    const payload = {
      person_id: getPersonId(),
      organisation_office_id: getPersonOrgOfficeId(),
      conversation_id: $ticketMainId,
      applicable_to: "customer",
      page_number: 1,
      page_size: 10,
    };
    if (!access_by_id) {
      payload.industry_category = "sales";
    }
    axios
      .post(BASIC_AI_URL + "/ai/user/message/suggestion", { ...payload })
      .then((response) => {
        suggestionText = response.data.rows;
        if (suggestionText.length !== 0) isHelpTextVisible = true;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChatList = () => {
    isChatListVisible = !isChatListVisible;
  };

  const sendHelpText = (helpText) => {
    textareaValue = helpText;
    sendMessage();
  };

  const setSelectedMessage = (selectedItem) => {
    selectedMessage = selectedItem;
    updateUnreadCountSocket(selectedItem.last_comment.id, selectedItem.id);
    isChatWidgetVisible = true;
    messages.set([]);
    messageLoading.set(false);
    // hasUserSentMessage = false;
    page = 1;
    ticketMainId.set(selectedItem.id);
    fetchMessageList();
    suggestionText = [];
    selectedRating = 0;
    isHelpTextVisible = false;
    if (selectedItem.conversation_status === "open") {
      fetchSuggestionsList();
    }
    if (selectedItem.is_picked === true) {
      disableMessageLoading.set(true);
      messageLoading.set(false);
    }
    $chatSocket.emit("join_ticket", $ticketMainId);
    conversationStatus = selectedItem.conversation_status;
    if ($DeviceType === "mobile") {
      isChatListVisible = false;
      isChatWidgetVisible = true;
    }
  };

  const callbackClicked = () => {
    $chatSocket.emit("chat_ai_ticket_message_v2", {
      app_type: "CUSTOMER",
      organisation_office_id: getPersonOrgOfficeId(),
      constituency_id: 1,
      ticket_main_id: $ticketMainId,
      person_id: getPersonId(),
      content: null,
      is_media_available: null,
      is_location_available: null,
      latitude: null,
      longitude: null,
      locality: null,
      address: null,
      category_id: null,
      required_inputs: null,
      ticket_id: null,
      menu_id: $selectedMenuItem?.id,
      required_input_data: {
        callback: "yes",
      },
    });
    PlaySound("https://testcdn.cleandesk.co.in/sounds/send-message-sound.mp3");
    messageLoading.set(true);
    aiMessageLoading.set(true);
  };

  function handleKeyDown(event) {
    // Stop keyboard event propagation
    event.stopPropagation();
  }

  const menuClicked = (e) => {
    console.log("menuClicked", e.detail.item);

    if (e.detail.item.id !== selectedMenu?.id) {
      if (e.detail.item?.master_menu_id === 2) {
        isSupportListVisible.set(!$isSupportListVisible);
        isChatListVisible = true;
      } else {
        if (e.detail.item.id !== 0) {
          selectedMenu = e.detail.item;
        }

        selectedMenuItem.set(e.detail.item);

        // startNewAiConversation.set({
        //   startNew: true,
        //   menu_id: e.detail.item.id,
        //   parent_menu_id: e.detail.item.parent_menu_id,
        //   menu_request_type: "intro",
        // });
      }
    }
  };
</script>

{#if isChatWidgetVisible}
  <div
    class={$DeviceType === "desktop" && "chat-widget-container"}
    style="{isChatListVisible && $DeviceType === 'desktop'
      ? 'right: 320px'
      : 'right: 20px'}; {$DeviceType === 'mobile'
      ? 'right: 0; width: 100%; top: 0; z-index: 9999998 !important;'
      : ''} height: 100%; background-color: #f3f2f2; position: fixed !important; display: flex; flex-direction: column;"
  >
    {#if isSettingVisible}
      <SettingsModal on:closeSettings={() => (isSettingVisible = false)} />
    {/if}

    <ChatHeader
      {isChatListVisible}
      on:chatListVisible={() => (isChatListVisible = !isChatListVisible)}
      on:settingsVisible={() => (isSettingVisible = true)}
      on:closeWidget={() => {
        isChatWidgetVisible = !isChatWidgetVisible;
        selectedMessage = null;
      }}
      name={$OrganisationDetails.title}
      avatar={$OrganisationDetails?.logo}
      {access_by_type}
      {access_by_id}
    />

    <ChatWindow
      {isVisible}
      {access_by_id}
      on:followOnUserInput={(e) => sendHelpText(e.detail)}
    />

    <!-- <div class="cleandesk-chat-body">
      <div
        use:scrollToBottom={messages}
        class="cleandesk-conversation-container"
        style="display: flex; overflow-y: scroll; flex-direction: column-reverse"
        bind:this={chatContainer}
      >
        <InfiniteScroll
          hasMore={newBatch.length}
          reverse={true}
          threshold={100}
          on:loadMore={() => {
            page++;
            fetchMessageList();
          }}
        />

        {#if messageLoading === true}
          <div class="message-item-container">
            <div style="width: 32px;">
              {#if !!aiDetails.title}
                <div
                  class="chat-header-avatar"
                  style="display: flex; align-items: center;"
                >
                  <img
                    src={DOMAIN + aiDetails?.person_avatar}
                    alt="a"
                    style="height: 24px; border-radius: 50%; margin-right: 8px;"
                  />
                </div>
              {/if}
            </div>
            <div style="width: 100%;">
              {#if !!aiDetails.title}
                <div
                  class="chat-header-avatar"
                  style="display: flex; align-items: center;"
                >
                  <div
                    class="header-profile-name"
                    style="font-size: 16px; font-weight: 600;"
                  >
                    {aiDetails?.title.toLowerCase()}
                  </div>
                </div>
              {/if}
              <div
                style="padding: 16px; margin: 0 0 0 -15px; width: 50px; border-radius: 8px"
              >
                <div class="spinner">
                  <div class="bounce1"></div>
                  <div class="bounce2"></div>
                  <div class="bounce3"></div>
                </div>
              </div>
            </div>
          </div>
        {/if}

        {#each messages as message, index (message.id)}
          {#if message.media_type === "application/pdf"}
            <div
              style="background-color: #fff; margin: 10px; width: auto; border-radius: 8px; max-width: 80%;"
            >
              <button
                style="width: 100%; padding: 10px; border: none; border-radius: 8px; cursor: pointer; background: none"
                type="button"
                on:click={openLink(message.media_url)}
              >
                <p>Click here to open pdf</p>
              </button>
            </div>
          {:else if message.media_type === "error_message"}
            <div class="message-item-container">
              <div class="message-item-body" style="padding: 8px;">
                <p>
                  {@html message?.content}
                </p>
              </div>
            </div>
          {/if}

          {#if !!message.person_id === true}
            {#if message.content_type === "json"}
              <MessageJson
                messageJson={message}
                on:feedbackData={sendFeedbackData}
                {selectedRating}
              />
            {:else if message.content_type === "html" && message.object_type === "product"}
              <div style="margin: 4px 10px;">
                {@html message?.content}
              </div>
            {:else}
              <MessageItem
                {message}
                {listOfUsers}
                isMine={message.person_id === parseInt(getPersonId())}
                on:typingFinished={handleTypingFinished}
              />
            {/if}
          {/if}
        {/each}
      </div>
    </div> -->

    <div class="cleandesk-chat-bar">
      <!-- <div
        class="chat-suggestions"
        style={!isHelpTextVisible && "display: none"}
      >
        {#each suggestionText as helpTextItem}
          <button
            class="chat-suggestion-item"
            on:click={() => sendHelpText(helpTextItem.message)}
          >
            <p>{helpTextItem.message}</p>
          </button>
        {/each}
      </div> -->

      <div
        class="chat-suggestions"
        style={!isHelpTextVisible && "display: none"}
      >
        {#each suggestionText as helpTextItem}
          {@const { backgroundColor, textColor } = getRandomSuggestionBgColor()}
          <button
            class="chat-suggestion-item"
            style="background-color: {backgroundColor}; color: {textColor}"
            on:click={() => sendHelpText(helpTextItem.message)}
          >
            <p style="color: {textColor}">{helpTextItem.message}</p>
          </button>
        {/each}
      </div>

      <!-- {#if conversationStatus === 'open'} -->
      <!-- {#if disableInput === false} -->
      <div class="cleandesk-chat-bar-message" style="padding: 6px;">
        <button
          type="sumbit"
          class="btn send-btn"
          style="width: 40px; height: 35px; border-radius: 50%; align-items: center; display: flex; justify-content: center; cursor: {$callbackDisabled ||
          $messageLoading
            ? 'not-allowed'
            : 'pointer'}"
          on:click={callbackClicked}
          disabled={$callbackDisabled || $messageLoading}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
            fill="#adadad"
            ><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path
              d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
            /></svg
          >
        </button>
        <form
          on:submit|preventDefault={sendMessage}
          style="display: flex; width:100%;"
        >
          <div
            style="padding: 0 4px; margin: 0 10px; border-radius: 4px; height: 35px; border: 1px solid #bdbdbd;
            display: flex; background-color: #fafafa; align-items: center; width: 100%"
          >
            <button
              type="button"
              class="btn send-btn"
              style="border-radius: 50%; display: none"
            >
              😊
            </button>
            <!-- <input class="chat-widget-input" autocomplete="off" placeholder="Type your query/request/complaint" rows="1" name="message-to-send" id="message-to-send" bind:value={textareaValue} style="width: 100%; border: none; outline:none; padding: 0 12px; font-size: 16px; color: #242424;" disabled={disableInput} /> -->
            <input
              class="chat-widget-input"
              autocomplete="off"
              placeholder="Type your query/request/complaint"
              rows="1"
              name="message-to-send"
              id="message-to-send"
              bind:value={textareaValue}
              style="width: 100%; border: none; outline:none; padding: 0 4px; font-size: 16px; color: #242424; background: #fafafa;"
              disabled={$disableInput}
              on:keydown={handleKeyDown}
            />
            <button
              type="button"
              class="btn send-btn"
              style="border-radius: 50%; display: none"
            >
              +
            </button>
          </div>
          <button
            type="submit"
            class="btn send-btn"
            style="width: 40px; height: 35px; border-radius: 50%; align-items: center; display: flex;"
            disabled={$disableInput}
          >
            {#if $messageLoading}
              <div class="send-spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>
            {:else}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path
                  d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4"
                  style="fill: {!!textareaValue
                    ? $OrganisationDetails?.primary_color
                    : '#adadad'}"
                />
              </svg>
            {/if}
          </button>
        </form>
      </div>
      <!-- {:else}
        <button class="ticket-pick-up primary-background-color" >
          <p class="custom-text-color">
            End of conversation
          </p>
        </button>
      {/if} -->

      <!-- <div
        class="widget-footer"
        style="background-color: #E0DEDE; height: 20px padding: 10px; display: flex; align-items: center;"
      >
        <p style="font-size: 10px; margin: 5px 8px">
          v{AppDefaults.APP_VERSION}
        </p>
        <a
          href={AppDefaults.CLEANDESK_URL}
          style="text-decoration:none; color: #000; display: flex; align-items: center; margin-left: auto"
        >
          <p style="padding: 6px; margin: 0px; font-size: 12px">
            Powered by CleanDesk Ai
          </p>
          <img
            src={DOMAIN + "/logo96tranparent.png?x=10000000"}
            style="height: 24px; margin-right: 8px"
            alt=""
          />
        </a>
      </div> -->
      <MobileBottomSheet isWidget={true} on:menuItemClick={menuClicked} />
    </div>
  </div>
{/if}

{#if isChatListVisible}
  <RightSideWindow
    {access_by_id}
    {access_by_type}
    {selectedMessage}
    on:selectedItem={(item) => setSelectedMessage(item.detail)}
    on:startNewChat={() => startNewAiConversation.set({ startNew: true })}
    on:chatListVisible={handleChatList}
    on:loginModalVisible={() => (loginModalVisible = true)}
    on:closeWidget={() => dispatch("closeWidget", true)}
  />
  <!-- <ChatListing
    {access_by_type}
    {selectedMessage}
    on:selectedItem={(item) => setSelectedMessage(item.detail)}
    on:startNewChat={() => startNewAiConversation.set({ startNew: true })}
    on:chatListVisible={handleChatList}
  /> -->
{/if}

{#if loginModalVisible}
  <LoginModal bind:showModal={loginModalVisible} />
{/if}
