<svelte:options tag="cleandesk-carousel" />

<script>
  import { onMount, onDestroy } from "svelte";

  export let items = [];
  export let autoplay = true;
  export let duration = 3000;

  export let imageStyle = "";

  let currentIndex = 0;
  let interval;

  function prev() {
    currentIndex = (currentIndex - 1 + items.length) % items.length;
  }

  function next() {
    currentIndex = (currentIndex + 1) % items.length;
  }

  onMount(() => {
    if (autoplay && items.length > 1) {
      interval = setInterval(next, duration);
    }
  });

  onDestroy(() => {
    clearInterval(interval);
  });
</script>

<div class="cleandesk-carousel">
  <div
    class="cleandesk-carousel-inner"
    style="transform: translateX({-currentIndex * 100}%)"
  >
    {#each items as item}
      <div class="cleandesk-carousel-item">
        <img src={item.src} alt={item.alt} style={imageStyle} />
      </div>
    {/each}
  </div>
  {#if items.length > 1}
    <div class="cleandesk-carousel-controls">
      <button class="cleandesk-carousel-control" on:click={prev}>&lt;</button>
      <button class="cleandesk-carousel-control" on:click={next}>&gt;</button>
    </div>
  {/if}
</div>
