<svelte:options tag="cleandesk-language-settings-modal" />

<script>
  import { createEventDispatcher } from "svelte";
  import axios from "axios";
  import { onMount } from "svelte";
  import {
    STANDARD_DICTIONARIES_MODULE,
    UTILITY_MODULE,
  } from "../../../config/api-variables";
  import {
    getAuthKey,
    getPersonId,
    getPersonOrgOfficeId,
  } from "../../../utils/cookie/user";
  import { selectedLanguage } from "../../../stores/authStores";
  import { alertMessageService } from "../../../common/AlertMessage/alertMessageService";

  export let showModal; // boolean

  let dialog; // HTMLDialogElement

  $: if (dialog && showModal) dialog.showModal();

  let accountSettingDetails = {};
  let languageList = [];
  let selectedObject = {};
  let selectedOption; // Set the initial selected option

  const headers = { Authorization: "Token " + getAuthKey() };

  onMount(() => {
    axios
      .post(
        STANDARD_DICTIONARIES_MODULE + "/organisation/language/list",
        { organisation_office_id: parseInt(getPersonOrgOfficeId()) },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          languageList = response.data.rows;
        }
        if (response.data.statusCode === "E100001")
          // alert(response.data.message);
          alertMessageService.error(response.data.message);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });

    axios
      .post(
        UTILITY_MODULE + "/account/settings/view",
        { person_id: parseInt(getPersonId()) },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          accountSettingDetails = response.data.rows;
          selectedOption = response.data.rows?.translate_language?.name;
          // selectedLanguage.set(selectedOption);
          selectedLanguage.set(
            response.data.rows?.translate_language?.language_name_local ||
              "English",
          );
        }
        if (response.data.statusCode === "E100001")
          alertMessageService.error(response.data.message);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  });

  const updateLanguage = () => {
    const payload = {
      id: parseInt(getPersonId()),
      person_id: parseInt(getPersonId()),
      organisation_office_id: parseInt(getPersonOrgOfficeId()),
      // translate_language: selectedObject,
      translate_language: {
        id: selectedObject?.id,
        code: selectedObject?.language_code,
        name: selectedObject?.language_name,
        iso_code: null,
        language_name_local: selectedObject?.language_name_local,
      },
      language_id: null,
      content_language_ids: null,
      date_format: null,
      time_format: null,
      currency_id: null,
    };

    axios
      .post(
        UTILITY_MODULE + "/account/settings/update",
        { ...payload },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          accountSettingDetails = response.data.rows;
          selectedLanguage.set(
            accountSettingDetails?.translate_language?.language_name_local,
          );

          alertMessageService.success("Language Updated Successfully!", 4000);
          dialog.close();
        }
        if (response.data.statusCode === "E100001")
          alert(response.data.message);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
    // console.log(payload, 'payload')
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events a11y-no-noninteractive-element-interactions -->
<dialog
  bind:this={dialog}
  on:close={() => (showModal = false)}
  on:click|self={() => dialog.close()}
>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div style="display: flex; flex-direction: column;" on:click|stopPropagation>
    <div>
      <p style="margin: 0 0 14px 0; font-weight: 600; font-size: 20px;">
        Language Settings
      </p>

      <div style="margin-top: 8px; display: flex; flex-direction: column;">
        {#each languageList as option (option.id)}
          <label
            class="label"
            style="display: flex; justify-content: space-between; padding: 4px;"
          >
            <span>{option.language_name_local}</span>
            <input
              class="radio"
              type="radio"
              name="options"
              value={option.language_name}
              group="options"
              style="margin-left: auto;"
              checked={selectedOption === option.language_name}
              on:change={() => {
                selectedOption = option.language_name;
                selectedObject = option;
              }}
            />
          </label>
        {/each}

        <!-- <button
          class="primary-background-color custom-text-color"
          style="width: 100%; padding: 10px; border: none; border-radius: 8px; margin-top: 8px;"
          on:click={updateLanguage}
          type="button"
        >
          <p>Submit</p>
        </button> -->
      </div>

      <div style="display: flex; justify-content: end;">
        <button
          style="display: block; margin: 4px; padding: 8px; background: #f3f2f2; border: none; cursor: pointer; border-radius: 8px"
          autofocus
          on:click={() => dialog.close()}>Close</button
        >
        <button
          class="primary-background-color custom-text-color"
          style="display: block; margin: 4px; padding: 8px; border: none; cursor: pointer; border-radius: 8px"
          on:click={() => updateLanguage()}>Submit</button
        >
      </div>
    </div>
  </div>
</dialog>
