import { writable } from "svelte/store";

export const selectedMenuItem = writable(null);

export const menuItemsList = writable([]);
export const menuItemsLoading = writable(true);

export const listingMenuItems = writable([]);

export const listingMenuItemsLoading = writable(true);

export const selectedListingMenuItem = writable(null);