<svelte:options tag="cleandesk-loading-on-scroll" />

<script>
  export let loading = false;
</script>

{#if loading}
  <div
    style="
        padding: 8px;
        display: flex;
        align-items: center;
        text-align: center;"
  >
    <div style="flex: 1; border-bottom: 1px solid #000;"></div>
    <p style="text-align: center; color: #979797; padding: 0px 6px;">Loading</p>
    <div style="flex: 1; border-bottom: 1px solid #000;"></div>
  </div>
{/if}
