import Cookies from "js-cookie";
import { UAParser } from "ua-parser-js";
import { isDemoWidget } from "../../stores/authStores";

export const TOKEN_KEY = "cleandesk_userToken";
export const PERSON_ID = "cleandesk_personId";
export const PERSON_ORG_OFFICE_ID = "cleandesk_personOrgOfficeId";
export const FCM_ID = "cleandesk_fcmId";
export const DEVICE_DATA = "cleandesk_deviceData";
export const CONVERSATION_ID = "cleandesk_conversationId";
export const ACCESS_BY_ID = "cleandesk_accessById";
export const SESSION_ID = "cleandesk_sessionId";
export const IS_USER_MANUAL_LOGGED_IN = "cleandesk_isUserManualLoggedIn";


let isDemo = false;

isDemoWidget.subscribe((value) => {
  isDemo = value;
});

const cookieNameGenerator = name =>
  (isDemo ? 'demo_' : '') + name;

export const setAuthKey = (token) => setCookie(TOKEN_KEY, token);
export const getAuthKey = () => getCookie(TOKEN_KEY);
export const removeAuthKey = () => removeCookie(TOKEN_KEY);

export const setPersonId = (personId) => setCookie(PERSON_ID, personId);
export const getPersonId = () => getCookie(PERSON_ID);
export const removePersonId = () => removeCookie(PERSON_ID);

export const getPersonOrgOfficeId = () => getCookie(PERSON_ORG_OFFICE_ID);
export const setPersonOrgOfficeId = (personOrgOfficeId) =>
  setCookie(PERSON_ORG_OFFICE_ID, personOrgOfficeId);
export const removePersonOrgOfficeId = () => removeCookie(PERSON_ORG_OFFICE_ID);

export const setAccessById = (accessById) =>
  setCookie(ACCESS_BY_ID, accessById);
export const getAccessById = () => getCookie(ACCESS_BY_ID);
export const removeAccessById = () => removeCookie(ACCESS_BY_ID);

export const setSessionId = (sessionId) =>
  setSessionStorage(SESSION_ID, sessionId);
export const getSessionId = () => getSessionStorage(SESSION_ID);
export const removeSessionId = () => removeSessionStorage(SESSION_ID);

export const setIsUserManualLoggedIn = (isLoggedIn) => setCookie(IS_USER_MANUAL_LOGGED_IN, isLoggedIn);
export const getIsUserManualLoggedIn = () => getCookie(IS_USER_MANUAL_LOGGED_IN);
export const removeIsUserManualLoggedIn = () => removeCookie(IS_USER_MANUAL_LOGGED_IN);


export const setFcmId = (fcmId) => {
  setCookie(FCM_ID, fcmId);

  const uniqueDeviceCode = new Date().getTime().toString();
  const deviceType = "browser";
  const userAgentDetails = UAParser(
    "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36"
  );

  const deviceData = {
    fcm_id: fcmId,
    app_type: "CUSTOMER",
    device_info: deviceType,
    device_type: deviceType,
    device_vendor: deviceType,
    mac_id: uniqueDeviceCode,
    browser_type: userAgentDetails.browser.name,
    browser_version: userAgentDetails.browser.version,
    os_type: userAgentDetails.os.name,
    os_version: userAgentDetails.os.version,
    manufacturer: userAgentDetails.device.vendor,
    location: "",
    gps_info: "info",
    machine_code: uniqueDeviceCode,
    application_type: "web",
    // ip_address: "49.36.137.205",
  };

  const deviceDataString = JSON.stringify(deviceData);

  setDeviceData(deviceDataString);
};

export const getFcmId = () => getCookie(FCM_ID);

export const setDeviceData = (deviceData) => setCookie(DEVICE_DATA, deviceData);
export const getDeviceData = () => getCookie(DEVICE_DATA);

export const setNonAuthConversationId = (conversationId) =>
  setCookieWithExpiry(CONVERSATION_ID, conversationId, 43200);

export const getNonAuthConversationId = () => getCookie(CONVERSATION_ID);

export const removeNonAuthConversationId = () => removeCookie(CONVERSATION_ID);

export const getCookie = (name) => {
  return Cookies.get(cookieNameGenerator(name));
};

export const setCookie = (name, value) => {
  Cookies.set(cookieNameGenerator(name), value);
};

export const removeCookie = (name) => {
  document.cookie = `${cookieNameGenerator(name)}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const getSessionStorage = (name) => {
  return sessionStorage.getItem(name);
};

export const setSessionStorage = (name, value) => {
  sessionStorage.setItem(name, value);
};

export const removeSessionStorage = (name) => {
  sessionStorage.removeItem(name);
};

export const setCookieWithExpiry = (name, value, minutesToExpire) => {
  const date = new Date();
  date.setTime(date.getTime() + minutesToExpire * 60 * 1000); // Convert minutes to milliseconds
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
};

export const logoutClearLocal = () => {
  removeAuthKey();
  removePersonId();
  removePersonOrgOfficeId();
  removeAccessById();
  removeIsUserManualLoggedIn();

  removeCookie(FCM_ID);
  removeCookie(DEVICE_DATA);
};
