<svelte:options tag="cleandesk-offers-tab" />

<script>
  import OffersCard from "./OffersCard.svelte";

  const couponsList = [
    {
      title: 'Coupon 1',
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
      code: 'HACBKA2182JBEK',
      details: ['Valid till 31st Dec 2024', 'Applicable on all products'],
    },
    {
      title: 'Coupon 2',
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
      code: 'HACBKA2182JBEK',
      details: ['Valid till 31st Dec 2024', 'Applicable on all products'],
    },
    {
      title: 'Coupon 3',
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
      code: 'HACBKA2182JBEK',
      details: ['Valid till 31st Dec 2024', 'Applicable on all products'],
    },
  ]

</script>

<div>
  {#each couponsList as coupon}
    {#key couponsList}
      <OffersCard {coupon} />
    {/key}
  {/each}
</div>
