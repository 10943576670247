<svelte:options tag="cleandesk-search-main" />

<script>
  import { createEventDispatcher } from "svelte";

  let searchQuery = null;
  let timeoutId;

  const dispatch = createEventDispatcher();

  function handleKeyDown(event) {
    // Stop keyboard event propagation
    event.stopPropagation();
  }

  const onSearch = (searchQuery) => {
    console.log("searching onSearch", searchQuery);
    cancelTimeout();
    timeoutId = setTimeout(() => {
      console.log("searching");
      dispatch("search", searchQuery);
    }, 1000);
  };

  const cancelTimeout = () => {
    clearTimeout(timeoutId);
  };
</script>

<div class="cleandesk-input-nosubmit">
  <input
    id="cleandesk-search-main"
    class="cleandesk-input-nosubmit"
    type="search"
    placeholder="Search..."
    bind:value={searchQuery}
    on:input={() => onSearch(searchQuery)}
    title="searchBar"
    on:keydown={handleKeyDown}
  />
</div>
