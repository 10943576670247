<svelte:options tag="cleandesk-team-card" />

<script>
  export let teamMember;

  const getSocialMediaIcon = (platform) => {
    switch (platform) {
      case "twitter":
        return `<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" id="twitter"><path d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"></path></svg>`;
      case "facebook":
        return `<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" id="facebook"><path d="M20.9,2H3.1A1.1,1.1,0,0,0,2,3.1V20.9A1.1,1.1,0,0,0,3.1,22h9.58V14.25h-2.6v-3h2.6V9a3.64,3.64,0,0,1,3.88-4,20.26,20.26,0,0,1,2.33.12v2.7H17.3c-1.26,0-1.5.6-1.5,1.47v1.93h3l-.39,3H15.8V22h5.1A1.1,1.1,0,0,0,22,20.9V3.1A1.1,1.1,0,0,0,20.9,2Z"></path></svg>`;
      case "linkedin":
        return `<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" id="linkedin"><path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z"></path></svg>`;
      case "instagram":
        return `<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" id="instagram"><path d="M17.34,5.46h0a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,17.34,5.46Zm4.6,2.42a7.59,7.59,0,0,0-.46-2.43,4.94,4.94,0,0,0-1.16-1.77,4.7,4.7,0,0,0-1.77-1.15,7.3,7.3,0,0,0-2.43-.47C15.06,2,14.72,2,12,2s-3.06,0-4.12.06a7.3,7.3,0,0,0-2.43.47A4.78,4.78,0,0,0,3.68,3.68,4.7,4.7,0,0,0,2.53,5.45a7.3,7.3,0,0,0-.47,2.43C2,8.94,2,9.28,2,12s0,3.06.06,4.12a7.3,7.3,0,0,0,.47,2.43,4.7,4.7,0,0,0,1.15,1.77,4.78,4.78,0,0,0,1.77,1.15,7.3,7.3,0,0,0,2.43.47C8.94,22,9.28,22,12,22s3.06,0,4.12-.06a7.3,7.3,0,0,0,2.43-.47,4.7,4.7,0,0,0,1.77-1.15,4.85,4.85,0,0,0,1.16-1.77,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12S22,8.94,21.94,7.88ZM20.14,16a5.61,5.61,0,0,1-.34,1.86,3.06,3.06,0,0,1-.75,1.15,3.19,3.19,0,0,1-1.15.75,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.73,5.73,0,0,1,6.1,19.8,3.27,3.27,0,0,1,5,19.05a3,3,0,0,1-.74-1.15A5.54,5.54,0,0,1,3.86,16c0-1-.06-1.37-.06-4s0-3,.06-4A5.54,5.54,0,0,1,4.21,6.1,3,3,0,0,1,5,5,3.14,3.14,0,0,1,6.1,4.2,5.73,5.73,0,0,1,8,3.86c1,0,1.37-.06,4-.06s3,0,4,.06a5.61,5.61,0,0,1,1.86.34A3.06,3.06,0,0,1,19.05,5,3.06,3.06,0,0,1,19.8,6.1,5.61,5.61,0,0,1,20.14,8c.05,1,.06,1.37.06,4S20.19,15,20.14,16ZM12,6.87A5.13,5.13,0,1,0,17.14,12,5.12,5.12,0,0,0,12,6.87Zm0,8.46A3.33,3.33,0,1,1,15.33,12,3.33,3.33,0,0,1,12,15.33Z"></path></svg>`;
      default:
        return null;
    }
  };

  const handleRedirect = (url) => {
    window.open(url, "_blank");
  };
</script>

<div class="cleandesk-product-main-div">
  {#if teamMember?.photo_url}
    <img
      class="cleandesk-product-img"
      src={teamMember?.photo_url}
      width="100%"
      alt="team member"
    />
  {/if}
  <div class="cleandesk-product-content-div">
    <div>
      {#if teamMember?.name}
        <p class="cleandesk-product-title">{teamMember?.name}</p>
      {/if}
      {#if teamMember?.role_position}
        <p
          class="cleandesk-product-title"
          style="font-weight: 200; font-size: 14px; margin-bottom: 8px; color: #777777"
        >
          {teamMember?.role_position}
        </p>
      {/if}
      {#if teamMember?.bio_short_description}
        <p class="cleandesk-product-description">
          {@html teamMember?.bio_short_description}
        </p>
      {/if}
    </div>

    <div style="display: flex; justify-content: space-between;">
      {#if teamMember?.social_media_urls && Object.keys(teamMember?.social_media_urls).length > 0}
        <div
          style="display: flex; justify-content: center; gap: 10px; padding 0 12px"
        >
          {#each Object.entries(teamMember?.social_media_urls).filter(([platform, url]) => url) as [platform, url]}
            <button on:click={() => handleRedirect(url)}>
              {@html getSocialMediaIcon(platform)}
            </button>
          {/each}
        </div>
      {/if}

      {#if teamMember?.call_to_action && teamMember?.cta_url}
        <button
          on:click={() => window.open(teamMember?.cta_url, "_blank")}
          class="cleandesk-buy-button primary-background-color custom-text-color"
          style="margin-left: auto;"
        >
          {teamMember?.call_to_action}
        </button>
      {/if}
    </div>
  </div>
</div>
