<svelte:options tag="message-json" />

<script>
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  export let messageJson;
  export let selectedRating;

  const dispatch = createEventDispatcher();

  let messageContent;
  let actionText;
  let commentValue = null;
  let disableSubmit = false;
  // let selectedRating = null;

  function selectRating(rating) {
    selectedRating = rating;
  }

  function handleInput(event) {
    commentValue = event.target.value;
  }

  // const havinesh = "{\"rating\":4,\"comment_text\":null,\"text\":\"Please rate this resolution\",\"action_text\":\"Submit\",\"card_type\":\"rating\"}"

  // console.log(JSON.parse(havinesh))

  function sendFeedback() {
    if (commentValue || selectedRating) {
      const data = {
        commentValue: commentValue,
        selectedRating: selectedRating,
        messageId: messageJson.id,
        ratingTitleText: messageContent?.text,
        actionText: messageContent.actions[1].action_text,
      };
      dispatch("feedbackData", data);
      disableSubmit = true;
    } else {
      alert("Please select your rating");
    }
  }

  onMount(() => {
    messageContent = JSON.parse(messageJson.content);
    if (messageContent?.rating) {
      selectedRating = messageContent?.rating;
      disableSubmit = true;
    }
    // if (messageContent.actions[1].action_text) {
    //   actionText = messageContent.actions[1].action_text;
    // } else {
    //   actionText = messageContent.action_text;
    //   console.log(actionText)
    // }
  });
</script>

<div
  style="background-color: #fff; margin: 10px; width: auto; border-radius: 8px; max-width: 80%;"
>
  <div style=" padding: 12px;">
    <p style="text-align: center;">
      {messageContent?.text}
    </p>
    <div class="rating">
      {#each [1, 2, 3, 4, 5] as star}
        <span
          class="star {star <= selectedRating ? 'filled' : ''}"
          on:click={() => selectRating(star)}
          on:keydown={() => selectRating(star)}
        >
          {#if star <= selectedRating}
            ★
          {:else}
            ☆
          {/if}
        </span>
      {/each}
    </div>
  </div>

  <button
    style="width: 100%; padding: 10px; border: none; border-top: 1.5px solid rgb(205 203 203); border-radius: 0 0 8px 8px; background: none;"
    on:click={sendFeedback}
    type="button"
    disabled={disableSubmit}
  >
    <p>
      <!-- {actionText} -->
      Submit
    </p>
  </button>
</div>
