<svelte:options tag="cleandesk-products-card" />

<script>
  export let product;

  let bodyContent;

  function extractBodyContent(htmlContent) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const body = doc.querySelector("body");

    if (body) {
      bodyContent = body.innerHTML;
    }
    const tempElement = document.createElement("div");
    tempElement.innerHTML = bodyContent;
    const textContent = tempElement.textContent || tempElement.innerText;
    bodyContent = textContent;
    return textContent;
  }
</script>

<div class="cleandesk-product-main-div">
  {#if product?.product_image_url}
    <img
      class="cleandesk-product-img"
      src={product?.product_image_url}
      width="100%"
      alt="product"
    />
  {/if}
  <div class="cleandesk-product-content-div">
    <div>
      {#if product?.product_name}
        <p class="cleandesk-product-title">{product?.product_name}</p>
      {/if}
      <div>
        {#each Array(5) as _, index}
          {#if index < Math.floor(product?.rating)}
            <!-- Check if current index is less than the integer part of the rating -->
            <svg class="cleandesk-star">
              <use xlink:href="#star"></use>
            </svg>
          {:else if index === Math.floor(product?.rating) && product?.rating % 1 !== 0}
            <!-- Check if current index is equal to the integer part of the rating and the rating is not a whole number -->
            <svg class="cleandesk-star" viewBox="0 0 24 24">
              <clipPath id="half-star-clip">
                <rect
                  x="0"
                  y="0"
                  width={24 * (product?.rating % 1)}
                  height="24"
                />
              </clipPath>
              <use xlink:href="#star" clip-path="url(#half-star-clip)" />
            </svg>
          {/if}
        {/each}
      </div>
      {#if product?.product_description}
        <p class="cleandesk-product-description">
          <!-- {@html product?.product_description} -->
          {extractBodyContent(product?.product_description)}
        </p>
      {/if}
    </div>

    <div class="cleandesk-price-div">
      <div>
        {#if product?.discounted_price}
          {#if product?.price}
            <span
              class="cleandesk-price-span"
              style=" text-decoration: line-through solid red 2px; font-weight: 500;"
            >
              {product?.price}</span
            >
          {/if}
          <span class="cleandesk-price-span" style="color: black"
            >{product?.discounted_price}</span
          >
        {/if}
        {#if product?.price && !product?.discounted_price}
          <span class="cleandesk-price-span">{product?.price}</span>
        {/if}
      </div>

      {#if product?.call_to_action && product?.cta_url}
        <button
          on:click={() => window.open(product?.cta_url, "_blank")}
          class="cleandesk-buy-button primary-background-color custom-text-color"
          >{product?.call_to_action}</button
        >
      {/if}
    </div>
  </div>
</div>

<svg style="display:none;">
  <symbol id="star" viewBox="0 0 24 24">
    <path
      d="M12 2l3.09 6.32 6.91.91-5 4.86 1.18 6.89-6.18-3.25-6.18 3.25 1.18-6.89-5-4.86 6.91-.91z"
    />
  </symbol>
  <symbol id="star-empty" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      d="M12 17.27l-4.09 2.45 1-5.83-3.34-3.26 5.83-.85L12 5.1l2.59 5.48 5.83.85-3.34 3.26 1 5.83z"
    />
  </symbol>
</svg>
