<svelte:options tag="cleandesk-list-menu-section" />

<script>
  import { DeviceType } from "../../../stores/authStores";
  import {
    listingMenuItems,
    selectedListingMenuItem,
  } from "../../../stores/menuStores";
  import CampaignListing from "../../CampaignListing/CampaignListing.svelte";
  import PortfolioListing from "../../PortfolioListing/PortfolioListing.svelte";
  import ProductListing from "../../ProductListing/ProductListing.svelte";
  import TeamListing from "../../TeamListing/TeamListing.svelte";
</script>

{#if $DeviceType !== "mobile"}
  <div
    class="tab-switch"
    style="display: flex; border-bottom: 0.5px solid #dcdfe4; background: #fff; height: 40px; -ms-overflow-style: none; scrollbar-width: none; overflow-x: auto; overflow-y: hidden;"
  >
    {#each $listingMenuItems as tabItem}
      <div
        style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin: 0px 8px; padding: 4px; cursor: pointer; background: #fff; {$selectedListingMenuItem?.id ===
        tabItem?.id
          ? 'border-bottom: 2px solid #000;'
          : ''}"
        on:click={() => selectedListingMenuItem.set(tabItem)}
        on:keydown={() => selectedListingMenuItem.set(tabItem)}
      >
        <p style="margin: 0; font-size: 16px">{tabItem?.name}</p>
      </div>
    {/each}
  </div>
{/if}

<div style="height: 100%; overflow: hidden;">
  {#if $selectedListingMenuItem?.master_list_menu_id === 2}
    <CampaignListing />
  {:else if $selectedListingMenuItem?.master_list_menu_id === 1}
    <ProductListing listType="product" />
  {:else if $selectedListingMenuItem?.master_list_menu_id === 3}
    <ProductListing listType="services" />
  {:else if $selectedListingMenuItem?.master_list_menu_id === 4}
    <TeamListing />
  {:else if $selectedListingMenuItem?.master_list_menu_id === 5}
    <PortfolioListing />
  {:else}
    <div
      style="height: 100%; display: flex; justify-content: center; align-items: center;"
    >
      <p style="margin: 0;">Coming soon!</p>
    </div>
  {/if}
</div>
