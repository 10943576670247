<svelte:options tag="cleandsk-full-screen-chat-window" />

<script>
  import { onMount } from "svelte";
  import {
    DeviceType,
    OrganisationDetails,
    userDetails,
  } from "../../../stores/authStores";
  import {
    messages,
    messageLoading,
    ticketMainId,
    disableInput,
    callbackDisabled,
    disableMessageLoading,
    isSessionTimerActive,
    sessionTimeoutId,
    hasUserSentMessage,
    aiMessageLoading,
  } from "../../../stores/chatStores";
  import {
    getAuthKey,
    getPersonId,
    getPersonOrgOfficeId,
  } from "../../../utils/cookie/user";
  import { chatSocket } from "../../../utils/socket/socketConfig";
  import axios from "axios";
  import {
    BASIC_AI_URL,
    LEGISLATOR_MANAGEMENT_MODULE,
  } from "../../../config/api-variables";
  import { PlaySound } from "../../../utils/PlaySound";
  import ChatWindow from "../../../common/ChatWindow/ChatWindow.svelte";
  import { getRandomSuggestionBgColor } from "../../../utils/tools/data-transformers";
  import { selectedMenuItem } from "../../../stores/menuStores";

  export let isVisible;
  export let access_by_id;

  let isHelpTextVisible = false;
  let suggestionText = [];

  let textareaValue = "";

  let menuSuggestionId = null;

  const sendMessage = () => {
    if (textareaValue !== "" && $messageLoading === false) {
      $chatSocket.emit("chat_ai_ticket_message_v2", {
        app_type: "CUSTOMER",
        organisation_office_id: getPersonOrgOfficeId(),
        constituency_id: 1,
        ticket_main_id: $ticketMainId,
        person_id: getPersonId(),
        content: textareaValue,
        is_media_available: null,
        is_location_available: null,
        latitude: null,
        longitude: null,
        locality: null,
        address: null,
        category_id: null,
        required_inputs: null,
        ticket_id: null,
        menu_id: $selectedMenuItem?.id,
        parent_menu_id: $selectedMenuItem?.parent_menu_id,
      });
      hasUserSentMessage.set(true);
      clearSessionTimeout();
      messages.update((value) => [
        {
          content: textareaValue,
          person_id: parseInt(getPersonId()),
          title: $userDetails?.first_name + " " + $userDetails?.last_name,
          person_avatar: $userDetails?.profile_image,
          id: new Date().getTime(),
          created_at: new Date().getTime(),
        },
        ...value,
      ]);
      textareaValue = "";
      if (!$disableMessageLoading) {
        messageLoading.set(true);
        aiMessageLoading.set(true);
      }
      isHelpTextVisible = false;
      suggestionText = [];
      PlaySound(
        "https://testcdn.cleandesk.co.in/sounds/send-message-sound.mp3",
      );
    }
  };

  const clearSessionTimeout = () => {
    isSessionTimerActive.set(false);
    clearTimeout($sessionTimeoutId);
  };

  function callbackClicked() {
    $chatSocket.emit("chat_ai_ticket_message_v2", {
      app_type: "CUSTOMER",
      organisation_office_id: getPersonOrgOfficeId(),
      constituency_id: 1,
      ticket_main_id: $ticketMainId,
      person_id: getPersonId(),
      content: textareaValue,
      is_media_available: null,
      is_location_available: null,
      latitude: null,
      longitude: null,
      locality: null,
      address: null,
      category_id: null,
      ticket_id: null,
      menu_id: $selectedMenuItem?.id,
      required_input_data: {
        callback: "yes",
      },
    });
    PlaySound("https://testcdn.cleandesk.co.in/sounds/send-message-sound.mp3");
    messageLoading.set(true);
    aiMessageLoading.set(true);
  }

  const sendHelpText = (helpText) => {
    textareaValue = helpText;
    sendMessage();
  };

  function handleKeyDown(event) {
    event.stopPropagation();
  }

  const fetchSuggestionsList = () => {
    const payload = {
      person_id: getPersonId(),
      organisation_office_id: getPersonOrgOfficeId(),
      conversation_id: $ticketMainId,
      applicable_to: "customer",
      page_number: 1,
      page_size: 10,
    };
    if (!access_by_id) {
      payload.industry_category = "sales";
    }
    axios
      .post(BASIC_AI_URL + "/ai/user/message/suggestion", { ...payload })
      .then((response) => {
        suggestionText = response.data.rows;
        if (suggestionText.length !== 0) isHelpTextVisible = true;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchMenuSuggestions = () => {
    const payload = {
      organisation_office_id: getPersonOrgOfficeId(),
      menu_id: menuSuggestionId,
    };
    const headers = { Authorization: "Token " + getAuthKey() };
    axios
      .post(
        LEGISLATOR_MANAGEMENT_MODULE + "/suggestion/list",
        { ...payload },
        { headers },
      )
      .then((response) => {
        const data = response.data.rows;
        suggestionText = data.map((item) => ({
          message_code: item.id,
          message: item.text,
        }));
        // suggestionText = response.data.rows;
        if (suggestionText.length !== 0) isHelpTextVisible = true;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  $: {
    if ($messageLoading === false) {
      if (menuSuggestionId) {
        fetchMenuSuggestions();
      } else {
        fetchSuggestionsList();
      }
    } else {
      isHelpTextVisible = false;
      suggestionText = [];
    }
  }
</script>

<div class="cleandesk-full-screen-chat-container">
  <ChatWindow
    {isVisible}
    {access_by_id}
    on:followOnUserInput={(e) => sendHelpText(e.detail)}
  />

  <div
    class="cleandesk-chat-bar"
    style="border: 0.5px solid #bdbdbd; background: #fff; {$DeviceType !==
      'mobile' && 'margin: 0 5%; border-radius: 0 0 12px 12px;'}
     width: auto;"
  >
    <div class="chat-suggestions" style={!isHelpTextVisible && "display: none"}>
      {#each suggestionText as helpTextItem}
        {@const { backgroundColor, textColor } = getRandomSuggestionBgColor()}
        <button
          class="chat-suggestion-item"
          style="background-color: {backgroundColor}; color: {textColor}"
          on:click={() => sendHelpText(helpTextItem.message)}
        >
          <p style="color: {textColor}">{helpTextItem.message}</p>
        </button>
      {/each}
    </div>

    <div class="cleandesk-chat-bar-message">
      <button
        type="sumbit"
        class="btn send-btn"
        style="width: 40px; height: 35px; border-radius: 50%; align-items: center; display: flex; cursor: {$callbackDisabled ||
        $messageLoading
          ? 'not-allowed'
          : 'pointer'}"
        on:click={callbackClicked}
        disabled={$callbackDisabled || $messageLoading}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
          fill="#adadad"
          ><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path
            d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"
          /></svg
        >
      </button>
      <form
        on:submit|preventDefault={sendMessage}
        style="display: flex; width:100%;"
      >
        <div
          style="padding: 0 4px; margin: 0 10px; border-radius: 4px; height: 35px; border: 1px solid #bdbdbd;
        display: flex; background-color: #fafafa; align-items: center; width: 100%"
        >
          <button
            type="button"
            class="btn send-btn"
            style="border-radius: 50%; display: none"
          >
            😊
          </button>
          <!-- <input class="chat-widget-input" autocomplete="off" placeholder="Type your query/request/complaint" rows="1" name="message-to-send" id="message-to-send" bind:value={textareaValue} style="width: 100%; border: none; outline:none; padding: 0 12px; font-size: 16px; color: #242424;" disabled={disableInput} /> -->
          <input
            class="chat-widget-input"
            autocomplete="off"
            placeholder="Type your query/request/complaint"
            rows="1"
            name="message-to-send"
            id="message-to-send"
            bind:value={textareaValue}
            style="width: 100%; border: none; outline:none; padding: 0 12px; font-size: 16px; color: #242424; background-color: #fafafa;"
            disabled={$disableInput}
            on:keydown={handleKeyDown}
          />
          <button
            type="button"
            class="btn send-btn"
            style="border-radius: 50%; display: none"
          >
            +
          </button>
        </div>
        <button
          type="submit"
          class="btn send-btn"
          style="width: 40px; height: 35px; border-radius: 50%; align-items: center; display: flex;"
          disabled={$disableInput}
        >
          {#if $messageLoading}
            <div class="send-spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          {:else}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path
                d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4"
                style="fill: {!!textareaValue
                  ? $OrganisationDetails?.primary_color
                  : '#adadad'}"
              />
            </svg>
          {/if}
        </button>
      </form>
    </div>
  </div>
</div>
