// src/utils/socket/socketManager.js
import io from "socket.io-client";
import { chatSocket, isSocketReset } from "./socketConfig";
import { getAuthKey } from "../cookie/user"
import { CHAT_DOMAIN } from "../../config/api-variables";
import { get } from 'svelte/store';

export const resetChatSocket = () => {

  const socket = get(chatSocket);
  isSocketReset.set(false);

  if (socket?.connected) {
    socket.disconnect();
  }

  // Create a new socket connection
  const socketConnection = io(CHAT_DOMAIN, {
    query: {
      token: getAuthKey(),
    },
  });

  // Set the new socket connection
  chatSocket.set(socketConnection);
  isSocketReset.set(true);

};
