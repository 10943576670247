<svelte:options tag="cleandesk-login-modal" />

<script>
  import axios from "axios";
  import {
    PROFILE_MANAGEMENT_MODULE,
    REGISTRATION_MODULE,
  } from "../../../config/api-variables";
  import {
    getAuthKey,
    getPersonId,
    getPersonOrgOfficeId,
    setAuthKey,
    setIsUserManualLoggedIn,
    setPersonId,
  } from "../../../utils/cookie/user";
  import {
    DeviceType,
    disableServiceMenu,
    isAuthenticated,
    isUserChanged,
    userDetails,
  } from "../../../stores/authStores";
  import { alertMessageService } from "../../../common/AlertMessage/alertMessageService";
  // import PhoneInput from "../../../common/FormItems/PhoneInput.svelte";
  import { resetChatSocket } from "../../../utils/socket/socketManager";
  import { startNewAiConversation } from "../../../stores/chatStores";

  export let showModal; // boolean

  let dialog; // HTMLDialogElement

  let isOtpSent = false;
  let otp = "";

  let loginType = "LOGIN"; // USERREGISTRATION
  let loginUsing = "email"; // email or mobile
  // let loginUsing = "mobile"; // email or mobile

  let countryCode = "IN";
  let phoneNumber = "";
  let selectedCountry = {};
  let email = "";
  let firstName = "";
  let lastName = "";

  let errorMessage = "";
  let isErrorVisible = false;

  let loginMessage = "";
  let isLoginMessageVisible = false;

  let loading = false;

  $: if (dialog && showModal) dialog.showModal();

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email) && !/\s/.test(email);
  };

  const sendOtp = () => {
    const payload = {
      organisation_office_id: parseInt(getPersonOrgOfficeId()),
      otp_reason: loginType,
      app_type: "WIDGET",
    };

    if (loginUsing === "email") {
      if (!isValidEmail(email)) {
        errorMessage = "Email should not contain whitespace and must be valid.";
        isErrorVisible = true;
        return;
      }
      payload.email = email;
    } else {
      payload.mobile_country_code = selectedCountry.isd_code.toString();
      payload.mobile = phoneNumber;
    }

    if (loginType === "USERREGISTRATION") {
      payload.first_name = firstName;
      payload.last_name = lastName;
    }

    loading = true;

    axios
      .post(REGISTRATION_MODULE + "/send-otp/", payload)
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          isOtpSent = true;
        } else {
          alertMessageService.error(response.data.message, 4000);
          errorMessage = response.data.message;
          isErrorVisible = true;
        }
        loading = false;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const verifyOtp = () => {
    loading = true;
    const payload = {
      app_type: "WIDGET",
    };

    if (loginUsing === "email") {
      payload.email = email;
      payload.email_otp = otp;
    } else {
      payload.mobile_country_code = selectedCountry.isd_code.toString();
      payload.mobile = phoneNumber;
      payload.mobile_otp = otp;
    }

    axios
      .post(REGISTRATION_MODULE + "/login/", payload)
      .then((response) => {
        loading = false;
        if (response.data.statusCode === "S10001") {
          setAuthKey(response.data.rows.token);
          setPersonId(response.data.rows.person_id);

          if (response.data.rows.message) {
            loginMessage = response.data.rows.message;
            isLoginMessageVisible = true;
            // Call fetchProfile after the timer finishes
            setTimeout(() => {
              fetchProfile();
              isLoginMessageVisible = false;
              loginMessage = "";
            }, 3000);
          } else {
            fetchProfile(); // Call fetchProfile directly
          }
          setIsUserManualLoggedIn(true);
          isUserChanged.set(true);
        } else {
          alertMessageService.error(response.data.message, 4000);
          errorMessage = response.data.message;
          isErrorVisible = true;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchProfile = () => {
    const headers = { Authorization: "Token " + getAuthKey() };

    axios
      .post(
        PROFILE_MANAGEMENT_MODULE + "/profile/",
        { person_id: null },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          userDetails.set(response?.data?.rows);
          isAuthenticated.set(true);
          dialog.close();

          resetChatSocket();

          startNewAiConversation.set({ startNew: true });

          disableServiceMenu.set(
            response?.data?.rows?.status === "active" ? false : true,
          );
        }
        if (response.data.statusCode === "E100001")
          alert(response.data.message);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  $: if (isErrorVisible) {
    setTimeout(() => {
      isErrorVisible = false;
    }, 3000);
  }

  $: if (isLoginMessageVisible) {
    setTimeout(() => {
      isLoginMessageVisible = false;
    }, 3000);
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events a11y-no-noninteractive-element-interactions -->
<dialog
  bind:this={dialog}
  on:close={() => (showModal = false)}
  on:click|self={() => dialog.close()}
  style="padding: 32px; max-width: 300px; {$DeviceType === 'mobile' &&
    'width: 70%; min-width: unset'}"
>
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div style="display: flex; flex-direction: column;" on:click|stopPropagation>
    <div>
      <div
        style="display: flex; justify-content: space-between; margin: 0 0 14px 0; align-items: center;"
      >
        <p style="font-weight: 600; font-size: 20px;">
          {#if isOtpSent}
            Verify OTP
          {:else if loginType === "LOGIN"}
            Sign In
          {:else}
            Register
          {/if}
        </p>
        <button
          class="cleandesk-login-close-button"
          on:click={() => dialog.close()}>Close</button
        >
      </div>

      {#if !isOtpSent}
        <div style="margin-top: 8px; display: flex; flex-direction: column;">
          {#if loginUsing === "email"}
            <label for="email" style="margin-bottom: 4px;"
              >Enter your registered email:</label
            >
            <input
              type="text"
              id="email"
              name="email"
              class="cleandesk-email-input"
              placeholder="Enter your email id"
              bind:value={email}
            />
          {:else}
            <!-- <label for="phone-number" style="margin-bottom: 4px;"
              >Enter your registered mobile:
            </label>
            <PhoneInput
              bind:countryCode
              bind:phoneNumber
              bind:selectedCountry
            /> -->
          {/if}
        </div>

        {#if loginType === "USERREGISTRATION"}
          <div>
            <div
              style="margin-top: 16px; display: flex; flex-direction: column;"
            >
              <label for="first-name" style="margin-bottom: 4px;"
                >First Name:
              </label>
              <input
                class="cleandesk-email-input"
                type="text"
                id="first-name"
                name="first-name"
                placeholder="Enter your first name"
                bind:value={firstName}
              />
            </div>
            <div
              style="margin-top: 16px; display: flex; flex-direction: column;"
            >
              <label for="last-name" style="margin-bottom: 4px;"
                >Last Name:
              </label>
              <input
                class="cleandesk-email-input"
                type="text"
                id="last-name"
                name="last-name"
                placeholder="Enter your last name"
                bind:value={lastName}
              />
            </div>
          </div>
        {/if}

        {#if isErrorVisible}
          <p style="color: #ff4d4f; margin-top: 8px; font-size: 14px;">
            {errorMessage}
          </p>
        {/if}

        {#if isLoginMessageVisible}
          <p style="color: #faad14; margin-top: 8px; font-size: 14px;">
            {loginMessage}
          </p>
        {/if}

        <!-- <div class="cleandesk-login-switch-container">
          <button
            class="cleandesk-login-switch-button"
            on:click={() =>
              (loginUsing = loginUsing === "email" ? "mobile" : "email")}
          >
            {loginType === "LOGIN" ? "Login " : "Register "}
            {#if loginUsing === "email"}
              using phone number
            {:else}
              using email
            {/if}
          </button>
        </div> -->

        <div class="cleandesk-login-switch-container">
          <button
            class="primary-background-color custom-text-color"
            style="display: block; margin: 4px; padding: 8px 16px; border: none; cursor: pointer; border-radius: 8px; font-size: 16px; {loading &&
              'cursor: not-allowed'}"
            on:click={() => sendOtp()}
            disabled={loading}
          >
            {#if loading}
              <div class="loader"></div>
            {:else}
              Submit
            {/if}
          </button>
        </div>

        <div class="cleandesk-login-switch-container">
          <button
            class="cleandesk-login-switch-button"
            on:click={() =>
              (loginType =
                loginType === "LOGIN" ? "USERREGISTRATION" : "LOGIN")}
          >
            {#if loginType === "LOGIN"}
              Create an account
            {:else}
              Login using existing account
            {/if}
          </button>
        </div>
      {:else}
        <div style="margin-top: 8px; display: flex; flex-direction: column;">
          <label for="email" style="margin-bottom: 4px;"
            >Enter {loginUsing === "email" ? "email" : "mobile"} OTP:</label
          >
          <input
            type="text"
            id="otp"
            name="otp"
            class="cleandesk-email-input"
            placeholder="Enter OTP"
            bind:value={otp}
          />

          {#if isErrorVisible}
            <p style="color: #ff4d4f; margin-top: 8px; font-size: 14px;">
              {errorMessage}
            </p>
          {/if}

          {#if isLoginMessageVisible}
            <p style="color: #faad14; margin-top: 8px; font-size: 14px;">
              {loginMessage}
            </p>
          {/if}

          <div class="cleandesk-login-switch-container">
            <button
              class="primary-background-color custom-text-color"
              style="display: block; margin: 4px; padding: 8px 16px; border: none; cursor: pointer; border-radius: 8px; font-size: 16px; {loading &&
                'cursor: not-allowed'}"
              on:click={() => verifyOtp()}
              disabled={loading}
            >
              {#if loading}
                <div class="loader"></div>
              {:else}
                Verify OTP
              {/if}
            </button>
          </div>
        </div>
      {/if}
    </div>
  </div>
</dialog>
