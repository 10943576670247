import { writable } from 'svelte/store';

const messages = writable([]);

const addMessage = (message) => {
  console.log(message,'message')
  const id = Math.random().toString(36).substr(2, 9);
  messages.update((msgs) => [...msgs, { ...message, id, duration: message.duration || 3000}]);
  // messages.update((msgs) => [{ ...message, id }, ...msgs]);
  setTimeout(() => {
    messages.update((msgs) => msgs.filter((msg) => msg.id !== id));
  }, message.duration || 3000);
};

const alertMessageService = {
  info: (msg, duration) => addMessage({ message: msg, type: 'info', duration }),
  success: (msg, duration) => addMessage({ message: msg, type: 'success', duration }),
  error: (msg, duration) => addMessage({ message: msg, type: 'error', duration }),
  warning: (msg, duration) => addMessage({ message: msg, type: 'warning', duration })
};

export { messages, alertMessageService };
