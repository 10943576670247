<svelte:options tag="cleandesk-left-side-menu" />

<script>
  import { createEventDispatcher } from "svelte";
  import MenuButton from "./MenuButton.svelte";
  import StartNewConversationButton from "../../common/components/StartNewConversationButton.svelte";
  import { LEGISLATOR_MANAGEMENT_MODULE } from "../../config/api-variables";
  import {
    getAuthKey,
    getPersonId,
    getPersonOrgOfficeId,
  } from "../../utils/cookie/user";
  import { onMount } from "svelte";
  import axios from "axios";
  import {
    aiMessageLoading,
    extraMenuClicked,
    messageLoading,
    startNewAiConversation,
    ticketMainId,
  } from "../../stores/chatStores";
  import {
    menuItemsList,
    menuItemsLoading,
    selectedMenuItem,
  } from "../../stores/menuStores";
  import { chatSocket } from "../../utils/socket/socketConfig";
  import { disableServiceMenu } from "../../stores/authStores";
  import { PlaySound } from "../../utils/PlaySound";

  export let expanded;
  let selectedMenu;

  const removeTheseIds = [12, 22];
  let removedItems = [];

  const dispatch = createEventDispatcher();

  const homeClicked = (e) => {
    // if (!e.detail.item.master_menu_id === 2) {
    // }
    selectedMenu = e.detail.item;
    console.log(e.detail.item, "e.detail");
    dispatch("menuItemClick", e.detail);
  };

  const expandLeftMenu = () => {
    dispatch("expandMenu");
  };

  let maxVisibleItems = 4; // Maximum number of items visible in the menu

  let visibleItems = [];
  let hiddenItems = [];
  let menuItems = [];

  const expandExtraMenu = () => {
    maxVisibleExtraItems = maxVisibleExtraItems === 3 ? 100000 : 3;
    maxVisibleItems = 4;
  };

  const havinesh = () => {
    maxVisibleItems = maxVisibleItems === 4 ? 100000 : 4;
    maxVisibleExtraItems = 3;
  };

  onMount(() => {
    fetchMenuList();
    fetchExtraMenuList();
  });

  let extraMenuItems = [];

  let visibleExtraItems = [];
  let hiddenExtraItems = [];
  let maxVisibleExtraItems = 3;

  $: {
    const menuMap = new Map();
    menuItems.forEach((item) => menuMap.set(item.id, item));

    let updated = true;
    while (updated) {
      updated = false;
      // Iterate through menuItems to create the hierarchical structure
      menuItems.forEach((item) => {
        if (item.parent_menu_id !== null) {
          const parentItem = menuMap.get(item.parent_menu_id);
          if (parentItem) {
            if (!parentItem.children) {
              parentItem.children = [];
            }
            // Check if the child item is not already in the parent's children array
            if (!parentItem.children.some((child) => child.id === item.id)) {
              parentItem.children.push(item);
              // Remove child item from the main array
              const index = menuItems.findIndex((i) => i.id === item.id);
              if (index !== -1) {
                menuItems.splice(index, 1);
                updated = true;
              }
            }
          }
        }
      });
    }
  }

  $: {
    if (menuItems.length <= maxVisibleItems) {
      visibleItems = menuItems;
      hiddenItems = [];
    } else {
      visibleItems = menuItems.slice(0, maxVisibleItems);
      hiddenItems = menuItems.slice(maxVisibleItems);
    }
  }

  $: {
    if (extraMenuItems.length <= maxVisibleExtraItems) {
      visibleExtraItems = extraMenuItems;
      hiddenExtraItems = [];
    } else {
      visibleExtraItems = extraMenuItems.slice(0, maxVisibleExtraItems);
      hiddenExtraItems = extraMenuItems.slice(maxVisibleExtraItems);
    }
  }

  $: if (visibleItems) {
    visibleItems = visibleItems.sort(
      (a, b) => parseFloat(a.weight) - parseFloat(b.weight),
    );
  }

  $: if (visibleExtraItems) {
    visibleExtraItems = visibleExtraItems.sort(
      (a, b) => parseFloat(a.weight) - parseFloat(b.weight),
    );
  }

  // $: if (menuItems.length > 0) {
  //   removedItems = menuItems.filter((item) => {
  //     if (removeTheseIds.includes(item.master_menu_id)) {
  //       // If master_menu_id is in removeTheseIds, remove it from menuItems
  //       return true; // Include in removedItems
  //     }
  //     return false; // Exclude from removedItems
  //   });

  //   // Update menuItems to exclude removed items
  //   menuItems = menuItems.filter(
  //     (item) => !removeTheseIds.includes(item.master_menu_id)
  //   );
  // }

  const fetchMenuList = () => {
    const headers = { Authorization: "Token " + getAuthKey() };

    axios
      .post(
        LEGISLATOR_MANAGEMENT_MODULE + "/menu/visible/list",
        { organisation_office_id: getPersonOrgOfficeId() },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          menuItems = response?.data?.rows;
          menuItemsLoading.set(false);
          menuItemsList.set(menuItems);
          if (menuItems.length > 0) {
            selectedMenuItem.set(menuItems[0]);
          } else {
            selectedMenuItem.set({ id: null });
          }
        }
        if (response.data.statusCode === "E100001")
          alert(response.data.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchExtraMenuList = () => {
    const headers = { Authorization: "Token " + getAuthKey() };

    axios
      .post(
        LEGISLATOR_MANAGEMENT_MODULE + "/service/menu/visible/list",
        { organisation_office_id: getPersonOrgOfficeId() },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          extraMenuItems = response?.data?.rows;
        }
        if (response.data.statusCode === "E100001")
          alert(response.data.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  let moreMenu;
  let moreExtraMenu;

  $: if (maxVisibleItems) {
    moreMenu = {
      id: "more",
      title: maxVisibleItems === 4 ? "More" : "Less",
      name: "More",
      icon: `<svg class="cleandesk-left-menu-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400Z"/></svg>`,
    };
  }

  $: if (maxVisibleExtraItems) {
    moreExtraMenu = {
      id: "more",
      title: maxVisibleExtraItems === 3 ? "More" : "Less",
      name: "More",
      icon: `<svg class="cleandesk-left-menu-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400Z"/></svg>`,
    };
  }

  const extraMenuItemClicked = (e) => {
    extraMenuClicked.set(e.detail.item);
    serviceClicked(e.detail.item);
  };

  function serviceClicked(item) {
    $chatSocket.emit("chat_ai_ticket_message_v2", {
      app_type: "CUSTOMER",
      organisation_office_id: getPersonOrgOfficeId(),
      constituency_id: 1,
      ticket_main_id: $ticketMainId,
      person_id: getPersonId(),
      content: item?.prompt,
      is_media_available: null,
      is_location_available: null,
      latitude: null,
      longitude: null,
      locality: null,
      address: null,
      category_id: null,
      ticket_id: null,
      menu_id: $selectedMenuItem?.id,
      required_input_data: {
        tool_name: item?.tool_name,
        prompt: item?.prompt,
      },
    });
    PlaySound("https://testcdn.cleandesk.co.in/sounds/send-message-sound.mp3");
    messageLoading.set(true);
    aiMessageLoading.set(true);
  }
</script>

<StartNewConversationButton
  primary={true}
  {expanded}
  extraStyles="margin-bottom: 8px;"
/>

{#each visibleItems as menuItem}
  <MenuButton
    {menuItem}
    {expanded}
    isSelected={$selectedMenuItem?.id === menuItem?.id}
    selectedMenuItem={$selectedMenuItem}
    on:menuItemClick={homeClicked}
    on:expandMenu={expandLeftMenu}
  />
{/each}

{#if menuItems.length > 4}
  <MenuButton
    menuItem={moreMenu}
    {expanded}
    on:menuItemClick={havinesh}
    on:expandMenu={expandLeftMenu}
  />
{/if}

{#if extraMenuItems.length > 0}
  <div style="margin-top: 8px;">
    <span
      style="font-size: 12px; font-weight: 600; font-style: italic; {expanded
        ? 'margin: 0 1em;'
        : 'margin: 0 0.2em;'} "
    >
      Service agents
    </span>
  </div>

  {#each visibleExtraItems as extraMenuItem}
    <MenuButton
      isExtraMenu={true}
      menuItem={extraMenuItem}
      {expanded}
      disableMenu={$disableServiceMenu || $messageLoading || $aiMessageLoading}
      isSelected={$selectedMenuItem?.id === $extraMenuClicked?.id}
      selectedMenuItem={$selectedMenuItem}
      on:menuItemClick={extraMenuItemClicked}
      on:expandMenu={expandLeftMenu}
    />
  {/each}

  {#if extraMenuItems.length > 3}
    <MenuButton
      menuItem={moreExtraMenu}
      {expanded}
      on:menuItemClick={expandExtraMenu}
      on:expandMenu={expandLeftMenu}
    />
  {/if}
{/if}
