<svelte:options tag="cleandesk-button" />

<script>
  import { startNewAiConversation } from "../../stores/chatStores";

  export let type = "primary";
  export let icon = `<svg class="icon-color" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/></svg>`;
  export let text = "New Conversation";
  export let expanded;
  export let disabled = false;
  export let extraStyles = "";
  export let textStyles = "";
</script>

<button
  class={type === "primary" && "primary-background-color custom-text-color"}
  {disabled}
  style="{!expanded
    ? 'margin: 0 0.2em;'
    : 'margin: 0 1em;'} border-radius: 4px; border: none; padding: 8px; {extraStyles}"
  on:click={() => startNewAiConversation.set({ startNew: true })}
>
  <div class="tooltip" style={"justify-content: center"}>
    {#if expanded}
      <div
        style="display: flex; align-items: center; justify-content: center; width: 100%;"
      >
        <div style="display: flex; align-items: center;">
          <div style="width: 24px;">
            {@html icon}
          </div>
          <span
            class="custom-text-color"
            style="margin-left: 12px; {textStyles}">{text}</span
          >
        </div>
      </div>
    {:else}
      <div style="width: 24px;">
        {@html icon}
      </div>
      <span class="tooltiptext">{text}</span>
    {/if}
  </div>
</button>
